import { deleteProperty, getProperty, setProperty } from 'dot-prop'
import { create as CreateRedStore } from 'zustand'
import { preSelectedFilter } from '../config/constant'

interface ISelectedFilter {
  [key: string]: any
}
interface IActiveFilters {
  filters: any[]
  selectedFilter: ISelectedFilter
  preSelectedFilter: ISelectedFilter
  setFilters: (filters: any) => void
  injectFilter: (ojb: object) => void
  setActiveFilters: (filters: ISelectedFilter) => void
  toggleActive: (key: string, value: string | number) => void
}

export const useFiltersStore = CreateRedStore<IActiveFilters>()((set) => ({
  filters: [],
  selectedFilter: {},
  preSelectedFilter,
  setFilters: (filters: any) => set(() => ({ filters })),
  setActiveFilters: (filters: ISelectedFilter) => set(() => ({ selectedFilter: filters })),
  injectFilter: (obj: { [key: string]: any }) =>
    set((state) => {
      const filterSelectedItem = { ...state.selectedFilter, ...obj }
      return { ...state, selectedFilter: filterSelectedItem }
    }),
  toggleActive: (key, value) =>
    set((state) => {
      const filterSelectedItem = state.selectedFilter
      const selectedValue = getProperty(filterSelectedItem, key)
      // if (selectedValue) {
      //   deleteProperty(filterSelectedItem, key)
      // }
      if (!value) {
        deleteProperty(filterSelectedItem, key)
      }
      setProperty(filterSelectedItem, key, value)
      return { ...state, selectedFilter: filterSelectedItem }
    }),
}))
