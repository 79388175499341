import { IPrice, ISelectedAddon } from "@config/types";
import useOrderAction from "@store/action-hook/useOrderAction";
import { useRedStore } from "@store/store";
import { useEffect } from "react";

// This is a hook file which is between UI and Store + Network.
/**
 *
 * Case 1: User just update the store
 * Case 2: User update and make the API Call -> API call resolve and update the store
 *
 * So this action hook can do both
 */

export default function usePriceAction() {
  const _updatePrice = useRedStore(state => state._updatePrice);
  const _updateAddons = useRedStore(state => state._updateAddons);
  const _setSelectedAddons = useRedStore(state => state._setSelectedAddons);
  const _restAddons = useRedStore(state => state._restAddons);
  const _resetPrice = useRedStore(state => state._resetPrice);
  const addonsMap = useRedStore(state => state.addonsMap);
  const selectedAddons = useRedStore(state => state.order.addons);
  const priceUpdateCount = useRedStore(state => state.priceUpdateCount);
  const orderPrice = useRedStore(state => state.order.fareAgreed);

  const { __updateFearAgreed } = useOrderAction();

  const __initiatePricing = (apiResponse: any) => {
    const { addons, ...rest } = apiResponse;
    _updateAddons(addons);
    __updatePrice(rest);
  };

  const __updatePrice = (response: IPrice) => {
    _updatePrice(response);
    const price = priceUpdateCount > 0 ? response.price : orderPrice || response.price;
    __updateFearAgreed(price, response.billToClient);
  };

  const __resetPrice = () => {
    _resetPrice();
  };

  const updateAddonsData = () => {
    if (selectedAddons && selectedAddons.length > 0 && Object.entries(addonsMap).length > 0) {
      // prepare the selected addons
      const prepAddons = selectedAddons.map((itm: ISelectedAddon) => ({ ...(addonsMap[itm.id] && { ...addonsMap[itm.id], quantity: itm.quantity, type: itm.type, price: itm.price }) }));
      _setSelectedAddons(prepAddons);
    }
  };

  useEffect(() => {
    updateAddonsData();
  }, [selectedAddons, addonsMap]);

  return { __updatePrice, __initiatePricing, __resetPrice };
}
