import React, { ReactElement } from "react";
import { COLOR_PLATE_FOR_CASE } from "../../config/constant";
import { Badge, Box, Flex, Text } from "@chakra-ui/react";
import { Icon } from "@iconify/react";

interface IProps {
  icon?: string;
  colorPalate?: string;
  title: string;
  description?: string;
  render?: () => ReactElement;
  isStrictMode?: boolean;
  subTitle?: string;
}

export default function TextRenderWithIcon({ icon, title, description, colorPalate = "PRIMARY", render, isStrictMode, subTitle }: IProps) {
  const { bg, color } = COLOR_PLATE_FOR_CASE[colorPalate];

  if (isStrictMode && (description === "" || description === null || description === undefined || description === "-" || description === "NA")) {
    return <></>;
  }

  return (
    <Flex>
      {icon && (
        <Flex justify={"center"} align={"center"} color={color} bg={bg} h={8} minW={8} mr={2} borderRadius={"full"}>
          <Icon icon={icon} fontSize={18} />
        </Flex>
      )}
      <Box>
        <Flex align={"center"}>
          <Text fontSize={"xs"} fontWeight={"normal"} color="blackAlpha.700" textTransform={"capitalize"}>
            {title || "-"}
          </Text>
          {subTitle && (
            <Box ml={2}>
              <Badge variant="solid" colorScheme="green" size={""}>
                Partner
              </Badge>
            </Box>
          )}
        </Flex>
        {render ? (
          render()
        ) : (
          <>
            <Text fontSize={"sm"} fontWeight={"semibold"} textTransform={"capitalize"} color="blackAlpha.900">
              {description || "-"}
            </Text>

            {subTitle && (
              <Text fontSize={"sm"} fontWeight={"normal"} textTransform={"capitalize"} color="blackAlpha.900">
                {subTitle || ""}
              </Text>
            )}
          </>
        )}
      </Box>
    </Flex>
  );
}
