import { useAppStore } from "@store/AppStore";
import { useRedStore } from "@store/store";
import { useEffect, useLayoutEffect } from "react";
import { useUnmount } from "usehooks-ts";

import { Text } from "@chakra-ui/react";
import { RULE_VERBOSE_KEY_MAPPER } from "@config/constant";
import { IMappedEvaluationData, IRuleEngineReason } from "@config/types";
import { distanceFormatter } from "@utils";
import AmbulanceCanServeAsAmbulanceRequested from "@components/molecules/AmbulanceCanServeAsAmbulanceRequested";

export const useUrlParams = () => {
  const { search } = window.location;
  const urlParams = new URLSearchParams(search);

  const getParams = (key: string) => {
    return urlParams.get(key);
  };

  const getAllParams = () => {
    const params: { [key: string]: any } = {};
    for (const pair of urlParams.entries()) {
      params[pair[0]] = pair[1];
    }
    return params;
  };
  return { getParams, getAllParams };
};

export const usePageTitle = (pageTitle: string) => {
  const setAppProps = useAppStore(state => state.setAppProps);

  useEffect(() => {
    setAppProps("currentPageTitle", pageTitle);
  }, []);
};

export const useTitle = (pageTitle: string) => {
  const _updateCurrentPageTitle = useRedStore(state => state._updateCurrentPageTitle);

  // useLayoutEffect(() => {
  //   _updateCurrentPageTitle(pageTitle);
  // }, []);

  // useUnmount(() => {
  //   _updateCurrentPageTitle("HM App");
  // });

  useEffect(() => {
    _updateCurrentPageTitle(pageTitle);
    return () => _updateCurrentPageTitle("HM App");
  }, [pageTitle]);
};

const useUtils = () => {
  const serializeReasons = (evaluationData: IMappedEvaluationData) => {
    const notAvailableReasons: IRuleEngineReason[] = [];

    const evaluationPayload: any = { ...evaluationData };

    Object.keys(evaluationPayload).forEach(key => {
      const payload: IRuleEngineReason = {
        title: RULE_VERBOSE_KEY_MAPPER[key],
        description: {
          render: () => <></>,
        },
        status: {
          type: "Pending",
          tooltip: "",
          message: "",
        },
      };

      const value = evaluationPayload[key];

      switch (key) {
        case "pilotAvailable": {
          if (!value) {
            payload.description.render = () => (
              <Text color={"red.500"} fontSize={"sm"} fontWeight={"semibold"}>
                Offline
              </Text>
            );
          } else if (!value?.status) {
            if (value.meta?.reason === "ON_TRIP") {
              payload.description.render = () => (
                <Text color={"red.500"} fontSize={"sm"} fontWeight={"semibold"}>
                  Serving Case: {value.meta.assignmentDetails.clientRequestId}
                </Text>
              );
            }
          }
          break;
        }
        case "ambulanceNearby": {
          if (value?.status) {
            payload.description.render = () => (
              <Text color={"red.500"} fontSize={"sm"} fontWeight={"semibold"}>
                Distance: {distanceFormatter(value?.meta?.distance)}
              </Text>
            );
          }
          break;
        }
        case "ambulanceOperational": {
          if (value && !value?.status) {
            if (value?.meta?.status) {
              payload.description.render = () => (
                <Text color={"red.500"} fontSize={"sm"} fontWeight={"semibold"}>
                  Reason: {value?.meta?.status}
                </Text>
              );
            }
          }
          break;
        }
        case "ambulanceCanServeAsAmbulanceRequested": {
          if (value) {
            payload.description.render = () => <AmbulanceCanServeAsAmbulanceRequested actualAmbulanceType={value?.meta?.actualAmbulanceType} requestedAmbulance={value?.meta?.requestedAmbulance} />;
          }
          break;
        }
      }

      if (value) {
        payload.status.type = value ? (value.status ? "Success" : "Reject") : "Pending";
      }

      notAvailableReasons.push(payload);
    });

    return notAvailableReasons;
  };

  return { serializeReasons };
};

export default useUtils;
