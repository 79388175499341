import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { IInEligibleSquad, IInEligibleSquadData, IMappedEvaluationData, IRuleEngineReason, IShiftData } from "../../config/types";
import { isValidObject, objectToQueryString, showErrorToast, showSuccessToast } from "../../utils";
import { getAllVehicle, getAllVehicleBasedOnGEO, getCurrentShift, getEvaluate, getGeoTag, getMultipleEntitiesById, getMultipleEntitiesLocation, voiceCall } from "../../api/service/daas";
import useUtils, { useUrlParams } from "../action/useUtils";
import useSyncMutation from "@hooks/action/useRedMutation";
import { useRedStore } from "@store/store";
import { getMdmAmbulance } from "api/service/mdm";
import { RULE_VERBOSE_KEY_MAPPER } from "@config/constant";
import { clickOnContactCallButton } from "@config/event";

interface Location {
  id: number;
  name: string;
  state: string;
  code: string;
  active: boolean;
  created_at: string;
  poc: string;
}

function getActiveEntityIdsString(locations: Location[], key: string, valueKey: string): string {
  const activeEntityIdsString = locations.map((itm: { [key: string]: any }) => `${key}=${itm[valueKey]}`).join("&");
  return activeEntityIdsString;
}

function getActiveEntityIdContractorString(locations: Location[], key: string, valueKey: string): string {
  const activeEntityIdsString = locations.map((itm: { [key: string]: any }) => `${key}=REDOS_PILOT_${itm[valueKey]}`).join("&");
  return activeEntityIdsString;
}

const useDaasApi = () => {
  const [finalData, setData] = useState([]);

  const { getParams } = useUrlParams();

  const par = getParams("city") === "all" ? "" : getParams("city");
  const city = par || "HYD";

  const payload = {
    city,
  };

  const query = objectToQueryString(payload);
  const { data: shiftData, ...shiftFetching }: any = useQuery([query, query], () => getCurrentShift(query), {
    enabled: !!payload.city,
  });

  //---------------------- Payload 2 -------------------
  const payload2 = {
    type: "PILOT",
  };

  const path = shiftData?.data?.data ? getActiveEntityIdsString(shiftData?.data?.data, "entityIds", "pilot_id") : "";

  const { data: multipleEntities, ...multipleEntitiesFetching }: any = useQuery([path, path], () => getMultipleEntitiesLocation(objectToQueryString(payload2).concat(path)), {
    enabled: !!path,
  });

  //---------------------- Payload 3 -------------------

  const daasPath = multipleEntities?.data && multipleEntities?.data?.length > 0 ? getActiveEntityIdsString(multipleEntities?.data, "ids", "daasEntityId") : "";

  const { data: multipleEntitiesById, ...multipleEntitiesByIdFetching } = useQuery([daasPath, daasPath], () => getMultipleEntitiesById(`?${daasPath}`), {
    enabled: !!daasPath,
    onSuccess: processFinalData,
  });

  function processFinalData(data: any) {
    const entitiesIdData: any = data?.data?.data;
    const entityData: any = multipleEntities?.data;
    const shiftDataRef: any = shiftData?.data?.data;

    function generateResponseBody(resp: IShiftData) {
      let finalObj: any = { ...resp };
      const { pilot_id } = resp;
      const entityDataRef = entityData.find((itm: any) => itm.entityId === pilot_id);

      if (entityDataRef) {
        finalObj = { ...finalObj, entityDataRef };

        const entityDataIdRef = entitiesIdData.find((itm: any) => itm.id === entityDataRef.daasEntityId);

        if (entityDataRef) {
          finalObj = { ...finalObj, entityDataIdRef };
        }
      }
      return finalObj;
    }

    if (shiftDataRef?.length > 0) {
      const fiin = shiftDataRef.map(generateResponseBody);
      setData(fiin);
    } else {
      setData([]);
    }
  }

  return {
    shiftData,
    multipleEntities,
    multipleEntitiesById,
    finalData,
    shiftFetching,
    multipleEntitiesFetching,
    multipleEntitiesByIdFetching,
  };
};

const useLighthouse = () => {
  const [apiData, setApiData] = useState({
    vehicleInSelectedCity: [],
    multipleEntities: [],
    currentShift: [],
  });

  const { getAllParams } = useUrlParams();
  const allParams = getAllParams();

  const [processVehicle, setProcessVehicle] = useState({});

  //---------------------- Fetch Vehicle in a city level (Independent API call) -------------------

  const { getParams } = useUrlParams();

  const par = getParams("city") === "all" ? "" : getParams("city");
  const city = par || "HYD";

  const vehicleInSelectedCityPayload = {
    entityType: "PILOT",
    city,
  };

  const vehicleInSelectedCityQuery = objectToQueryString(vehicleInSelectedCityPayload);

  // location of vehicle
  const { data: vehicleInSelectedCity, ...vehicleInSelectedCityAPIRequest }: any = useQuery(
    [vehicleInSelectedCityQuery, vehicleInSelectedCityQuery],
    () => getAllVehicleBasedOnGEO(vehicleInSelectedCityQuery),
    {
      enabled: !!vehicleInSelectedCityPayload.city,
      onSuccess: (data: any) => {
        const response = data?.data?.["PILOT"]?.[0] || [];
        setApiData(prev => ({ ...prev, vehicleInSelectedCity: response }));
      },
    }
  );

  // TODO: Need to call these 2 api getAllVehicleBasedOnGEO, getAllDaaSVehicle

  //---------------------- Fetch Pilots In Current Shift (Independent API call) -------------------

  const currentShiftPayload = {
    city,
  };

  const currentShiftQuery = objectToQueryString(currentShiftPayload);

  const { data: currentShiftResponse, ...currentShiftAPIRequest }: any = useQuery([currentShiftQuery, currentShiftQuery], () => getAllVehicle(currentShiftQuery), {
    enabled: !!currentShiftPayload.city && !!vehicleInSelectedCity?.data?.["PILOT"]?.[0],
    onSuccess: (data: any) => {
      const response = data?.data?.data || [];
      setApiData(prev => ({ ...prev, currentShift: response }));
    },
  });

  //---------------------- This API depends on above response -------------------
  const getMultipleEntitiesByIdPayload = {
    limit: 35,
    offset: 0,
  };

  const path = currentShiftResponse?.data?.data ? getActiveEntityIdContractorString(currentShiftResponse?.data?.data, "ids", "pilot_id") : "";

  const { data: multipleEntities, ...multipleEntitiesAPIRequest }: any = useQuery([path, path], () => getMultipleEntitiesById(objectToQueryString(getMultipleEntitiesByIdPayload).concat(path)), {
    enabled: !!path,
    onSuccess: (data: any) => {
      const response = data?.data?.data || [];
      setApiData(prev => ({ ...prev, multipleEntities: response }));

      const vehicleInTheCity = vehicleInSelectedCity?.data?.["PILOT"]?.[0];

      // active vehicle
      const processPay = currentShiftResponse?.data?.data?.reduce((acc: any, curr: any) => ({ ...acc, [curr.pilot_id]: curr }), {});

      // inject pilot & location data
      response?.forEach((element: any) => {
        const key = element.id.replace("REDOS_PILOT_", "");

        if (processPay[key] && vehicleInTheCity[key]) {
          processPay[key] = {
            ...processPay[key],
            pilot: element,
            location: vehicleInTheCity[key],
          };
        }
      });

      setProcessVehicle(processPay);
      // inject location data
    },
  });

  return { processVehicle: doFilter(processVehicle, allParams) };
};

const useGeoTag = ({ assignmentId }: { assignmentId?: string }) => {
  return useQuery([`geo-tag${assignmentId}`], () => getGeoTag(assignmentId), {
    enabled: !!assignmentId,
  });
};

export { useDaasApi, useLighthouse, useGeoTag };

const doFilter = (data: any, filter: any) => {
  const key = Object.keys(filter)[0];
  const value = Object.values(filter)[0];
  let newObj = { ...data };

  const getStatus = (availability: string, reason: string) => {
    return availability ? "ONLINE" : reason === "ON_TRIP" ? "ON_TRIP" : "OFFLINE";
  };

  switch (key) {
    case "vehicle-status":
      Object.keys(data).forEach(statusKey => {
        const status = getStatus(data[statusKey]?.pilot?.availability, data[statusKey]?.pilot?.reason);
        if (status !== value) {
          delete newObj[statusKey];
        }
      });
      break;
    case "vehicle-type":
      Object.keys(data).forEach(dataKey => {
        const isMatch = data[dataKey]?.fleet_type === value;
        if (!isMatch) {
          delete newObj[dataKey];
        }
      });
      break;
    case "ambulanceNumber":
      Object.keys(data).forEach(dataKey => {
        const isMatch = data[dataKey]?.registration_number === value?.toString().toUpperCase();
        if (!isMatch) {
          delete newObj[dataKey];
        }
      });
      break;
    default:
      newObj = data;
  }
  // else if()
  console.log("Data: ", newObj, " FIlter: ", filter);
  return newObj;
};

export const useVoiceCall = () => {
  const loginUser = useRedStore(state => state.user);
  const { mutateAsync, ...rest } = useSyncMutation(voiceCall);

  const makeVoiceCall = (to: string) => {
    const contact = loginUser?.phoneNo || loginUser?.primaryPhoneNo || loginUser?.altPhoneNo;
    try {
      if (!to || !contact) throw Error();
      const payload = {
        from: loginUser?.phoneNo,
        to: to,
      };
      mutateAsync(payload);
      clickOnContactCallButton({ from: loginUser?.phoneNo, to: to });
      showSuccessToast("We Are Connecting Your Call, Please Wait....", 2000);
    } catch (err) {
      showErrorToast("Unable to Make Voice call.");
    }
  };

  return { makeVoiceCall, ...rest };
};

export default function useRuleVerboseData(payload: IInEligibleSquad) {
  const [isLoading, setIsLoading] = useState(true);
  const { orderId, ambNumber } = payload;
  const { serializeReasons } = useUtils();
  const [evaluatedData, setEvaluatedData] = useState({
    squadData: [],
    entityData: {},
    mappedEvaluationData: [],
  });

  const squadDataNormalizer = (squad: IInEligibleSquadData) => {
    const {
      entity_type,
      metaData: { mobile, userName },
    } = squad;

    const payload = {
      label: entity_type,
      name: userName,
      mobile: mobile,
      logoSize: "4",
      enableMaskingCall: true,
      status,
    };

    return payload;
  };

  const serializeSquadData = (squadData: IInEligibleSquadData[]) => {
    return squadData?.map(squadDataNormalizer)?.filter(itm => itm.label !== "Fleet");
  };

  const KEY = `rule-engine-id-${orderId}-${ambNumber}`;

  if (!orderId || !ambNumber) {
    showErrorToast("OrderId or Ambulance Number is missing.");
    throw "OrderId or Ambulance Number is missing.";
  }

  const handleOnSuccess = (data: any) => {
    const ruleResult = data?.data?.data?.ruleResults;
    const _payload: any = {};

    if (!ruleResult || !isValidObject(ruleResult)) {
      showErrorToast("No Result found from Rule Engine.");
    } else if (!ruleResult[ambNumber]) {
      showErrorToast(`No Result found from vehicle id: ${ambNumber}`);
    } else {
      const { squadData, entityData, mappedEvaluationData } = ruleResult[ambNumber];
      if (Array?.isArray(squadData) && squadData?.length > 0) {
        _payload["squadData"] = serializeSquadData(squadData);
      }
      if (isValidObject(mappedEvaluationData)) {
        _payload["mappedEvaluationData"] = serializeReasons(mappedEvaluationData);
      }
      setEvaluatedData(prev => ({ ...prev, ..._payload }));
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const evaluated = useQuery([KEY], () => getEvaluate(payload), {
    enabled: !!payload?.orderId && !!ambNumber,
    cacheTime: 1000 * 30, // 0 seconds
    onSuccess: handleOnSuccess,
    refetchOnWindowFocus: false,
    onError: (error: any) => {
      setIsLoading(false);
      console.log("error: ", error);
      showErrorToast("Failed to fetch vehicle info.");
    },
  });

  return { isLoading, evaluated, evaluatedData };
}
