export default {
  featureName: "Covid Feature",
  isActive: true,
  userSegments: [],
  payloadInject: function (payload: any) {
    if (!this.isActive) {
      delete payload["isCovid"];
    }
    return payload;
  },
};
