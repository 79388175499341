import { CLOUD_FUNCTION_BASE_URL, ENVIRONMENT, VITE_RED_UTILS } from "../axios-interceptor";
import request from "../request";
import data from "../../config/partner.json";
import { SYMPTOMS } from "../../config/constant";
import axios from "axios";

const urls = {
  latestAppVersionUrl: "/app/config",
  getDashboardMetricsUrl: `/lighthouse-v2-${ENVIRONMENT}-getDashboardMetrics`,
  icdUrl: "https://redos-cc.netlify.app/.netlify/functions/icd-api?q=",
  fileUploadUrl: "/.netlify/functions/upload-asset",
};

export const getLatestAppVersion = () => {
  const response = request("GET", `${urls.latestAppVersionUrl}`);
  return response;
};

export const getSourceData = () => {
  return new Promise((resolve, reject) => {
    resolve(data);
  });
};

export const getAnalyticsReport = (payload?: any) => {
  const response = request("POST", `${urls.getDashboardMetricsUrl}`, payload, {
    baseURL: CLOUD_FUNCTION_BASE_URL,
  });
  return response;
};

export const getFileUploadUrl = (payload?: any) => {
  const url = `${VITE_RED_UTILS}${urls.fileUploadUrl}`;
  const response = axios.post(url, payload);

  return response;
};

const formatString = (str: any) => {
  const filter1 = str.replaceAll("<em class='found'>", "");
  const filter2 = filter1.replaceAll("<em class='nonwbe'>", "");
  return filter2.replaceAll("</em>", "").toUpperCase();
};

export const getICDData = async (query: string) => {
  const response = await fetch(`${urls.icdUrl}${query}`);
  const data = await response.json();
  const resp = data?.destinationEntities;

  const filterVal = SYMPTOMS.filter(itm => itm?.toLowerCase().includes(query.toLowerCase())).map(itm => ({ label: itm, value: itm }));

  const filterICDData = resp.map((itm: any) => ({
    label: formatString(itm.title),
    value: formatString(itm.title),
  }));

  console.log("sosososo: ", filterICDData);

  return [...filterVal, ...filterICDData];
};
