import { Box, Text } from "@chakra-ui/react";

interface Props {
  title: string;
  description: string;
}

export default function TextRenderWithHeading({ title, description }: Props) {
  return (
    <Box>
      <Text fontSize={"xs"} fontWeight={"normal"} color="blackAlpha.700" textTransform={"capitalize"}>
        {title || "-"}
      </Text>
      <Text fontSize={"sm"} fontWeight={"semibold"} textTransform={"capitalize"} color="blackAlpha.900">
        {description || "-"}
      </Text>
    </Box>
  );
}
