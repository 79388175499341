import { objectToQueryStringOmitEmpty } from "@utils";
import { CLOUD_FUNCTION_BASE_URL, ENVIRONMENT } from "../axios-interceptor";
import request from "../request";
import { DEFAULT_VEHICLE_SEARCH_RADIUS } from "@config/constant";

const urls = {
  currentShift: `/user-data-${ENVIRONMENT}-pilotsInCurrentShift`,
  multipleEntityLocation: "/geo/loc/getMultipleEntitiesLocation",
  multipleEntitiesById: "/daas/entities/getMultipleEntitiesById",
  pilotsInCurrentShifts: `/user-data-${ENVIRONMENT}-pilotsInCurrentShift`,
  geoLocationSearchURL: "/geo/loc/search",
  geoTag: "/dispatch/",
  dispatchEntity: (id: string) => `/order/${id}/dispatch/entities`,
  entityFare: (id: string) => `/pricing/fares/${id}`,
  voiceCallUrl: import.meta.env.VITE_VOICE_CALL_CLOUD_FUNCTION_URL,
  evaluateUrl: (id: string, vehicleId: string) => `order/${id}/evaluate/${vehicleId}`,
  nearbyUrl: `/location/nearby`,
};

export const voiceCall = (payload?: any) => {
  const { from, to } = payload;
  const response = request("POST", `${urls.voiceCallUrl}?caller=+91${from}&receiver=${to}`, {
    baseURL: CLOUD_FUNCTION_BASE_URL,
  });
  return response;
};

export const getEvaluate = (payload?: any) => {
  const url = urls.evaluateUrl(payload?.orderId, payload?.ambNumber);
  const response = request("GET", `${url}?searchRadius=${DEFAULT_VEHICLE_SEARCH_RADIUS}`);
  return response;
};

export const getCurrentShift = (query?: string) => {
  const response = request("GET", `${urls.currentShift}${query}`, {}, { baseURL: CLOUD_FUNCTION_BASE_URL });
  return response;
};

export const getAllNearbyVehicle = (query?: string) => {
  const response = request("GET", `${urls.nearbyUrl}?${query}`);
  return response;
};

export const getMultipleEntitiesLocation = (query?: string) => {
  const response = request("GET", `${urls.multipleEntityLocation}${query}`);
  return response;
};

export const getMultipleEntitiesById = (query?: string) => {
  const response = request("GET", `${urls.multipleEntitiesById}${query}`);
  return response;
};

export const getDispatchEntities = (queryObj: any) => {
  const { orderId, ...rest } = queryObj;
  const query = objectToQueryStringOmitEmpty(rest);
  const response = request("GET", `${urls.dispatchEntity(orderId)}${query}`);
  return response;
};

export const getEntitiesPrice = (queryObj: any) => {
  const { orderId, ...rest } = queryObj;
  const query = objectToQueryStringOmitEmpty(rest);
  const response = request("GET", `${urls.entityFare(orderId)}${query}`);
  return response;
};

export const getAllVehicle = (query?: string) => {
  const response = request("GET", `${urls.pilotsInCurrentShifts}${query}`, {}, { baseURL: CLOUD_FUNCTION_BASE_URL });
  return response;
};

export const getAllVehicleBasedOnGEO = (query?: string) => {
  const response = request("GET", `${urls.geoLocationSearchURL}${query}`);
  return response;
};

export const getGeoTag = (assignmentId?: string) => {
  const response = request("GET", `${urls.geoTag}${assignmentId}`);
  return response;
};
