import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import dayjs from "dayjs";
import { useRef } from "react";
import { PageLoading } from "../wrapper/NetworkWrapper";
import { MAP_INIT_OBJ } from "../../config/constant";

const GeoTagMap = ({ data }: any) => {
  const markerRef: any = useRef(null);
  const { isLoaded } = useLoadScript(MAP_INIT_OBJ);

  const darkSlate = useColorModeValue("slate.800", "slate.900");

  if (!isLoaded) return <PageLoading />;

  const center = {
    lat: 17.385,
    lng: 78.4867,
  };
  const zoom = 11;

  const onLoad = async (map: google.maps.Map) => {
    console.log("Map: ", map);
    // await fetchFleetLocations(headerState.city);
    // fitBoundsAllowed.current = true
    // setMap(map);
  };

  const handleOnLoad = (markerInstance: any) => {
    markerRef.current = markerInstance;
  };

  return (
    <>
      <Box
        w={"auto"}
        h={"87vh"}
        shadow={"md"}
        borderRadius={"md"}
        overflow={"hidden"}
      >
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          options={{ mapTypeControl: false }}
        >
          {data?.map((itm: any, index: number) => (
            <MarkerF
              //  onMouseOver={onMouseEnter}
              //  onMouseOut={onMouseExit}
              position={itm?.geo}
              //  onClick={() => onMarkerSelect()}
              onLoad={handleOnLoad}
              //  icon={markerIconFactory(fleet_type)}
            >
              <InfoWindowF position={itm.geo} key={index}>
                <Box>
                  <Flex color={"black"}>
                    <Box
                      fontWeight={"normal"}
                      fontSize={"xs"}
                      mr={1}
                      color={"black"}
                    >
                      Type:
                    </Box>
                    <Box
                      fontWeight={"semibold"}
                      fontSize={"xs"}
                      textTransform={"capitalize"}
                      color={darkSlate}
                    >
                      {itm.label}
                    </Box>
                  </Flex>
                  <Flex>
                    <Box
                      color={darkSlate}
                      fontWeight={"normal"}
                      fontSize={"xs"}
                      mr={1}
                    >
                      Timestamp:
                    </Box>
                    <Box
                      color={darkSlate}
                      fontWeight={"semibold"}
                      fontSize={"xs"}
                      textTransform={"capitalize"}
                    >
                      {itm.timestamp
                        ? dayjs
                            .unix(itm.timestamp / 1000)
                            .format("DD MMM YYYY hh:mm A")
                        : "-"}
                    </Box>
                  </Flex>
                </Box>
              </InfoWindowF>
            </MarkerF>
          ))}
        </GoogleMap>
      </Box>
    </>
  );
};

export default GeoTagMap;
