import { Box, Text, Flex, Tag, TagLabel, Avatar } from "@chakra-ui/react";
import { Icon as Iconify } from "@iconify/react";
import {
  CASE_LISTING_TABLE_BILLING_COLUMN_COLOR_MAP,
  CASE_LISTING_TABLE_BILLING_COLUMN_ICON_MAP,
  CASE_LISTING_TABLE_BOOKING_COLUMN_COLOR_MAP,
  CASE_LISTING_TABLE_BOOKING_COLUMN_ICON_MAP,
  CASE_LISTING_TABLE_CASE_STATUS_COLUMN_COLOR_MAP,
  CASE_LISTING_TABLE_CASE_STATUS_COLUMN_ICON_MAP,
  CASE_LISTING_TABLE_DISPATCH_COLUMN_COLOR_MAP,
  CASE_LISTING_TABLE_DISPATCH_COLUMN_ICON_MAP,
  CASE_LISTING_TABLE_PLATFORM_COLUMN_COLOR_MAP,
  CASE_LISTING_TABLE_PLATFORM_COLUMN_ICON_MAP,
} from "../../config/constant";

export const Header = ({ text }: { text: string }) => (
  <Text align={"left"} fontSize={"xs"}>
    {text}
  </Text>
);

export const GenerateByComponent = ({ email }: { email: string }) => {
  return email ? (
    <Flex align={"center"}>
      <Avatar size="xs" name={email} my={0} mr={2} fontSize={20} />
      <Text fontWeight={"medium"} letterSpacing={"wide"}>
        {email}
      </Text>
    </Flex>
  ) : (
    <>-</>
  );
};

export const PlatformRender = ({ platform }: { platform: string }) => {
  const defaultColor = "slate.800";
  let transformText = (platform || "unknown")?.toString().toUpperCase();
  if (transformText === "HM-APP") {
    transformText = "HM_APP";
  }

  const tagColorScheme =
    CASE_LISTING_TABLE_PLATFORM_COLUMN_COLOR_MAP[transformText] || defaultColor;
  const tagIcon = CASE_LISTING_TABLE_PLATFORM_COLUMN_ICON_MAP[transformText];

  return (
    <Tag size="sm" colorScheme={tagColorScheme} borderRadius="full">
      <Box mr={1}>
        <Iconify icon={tagIcon} fontSize={16} />
      </Box>
      <TagLabel
        fontWeight="600"
        textTransform={"uppercase"}
        letterSpacing={"wide"}
        fontSize="xs"
      >
        {transformText}
      </TagLabel>
    </Tag>
  );
};

export const BookingRender = ({ booking }: { booking: string }) => {
  const defaultColor = "slate.800";
  const transformText = booking?.toString().toUpperCase();

  const tagColorScheme =
    CASE_LISTING_TABLE_BOOKING_COLUMN_COLOR_MAP[transformText] || defaultColor;
  const tagIcon = CASE_LISTING_TABLE_BOOKING_COLUMN_ICON_MAP[transformText];

  return (
    <Tag size="sm" colorScheme={tagColorScheme} borderRadius="full">
      <Box mr={1}>
        <Iconify icon={tagIcon} fontSize={16} />
      </Box>
      <TagLabel
        fontWeight="600"
        textTransform={"uppercase"}
        letterSpacing={"wide"}
        fontSize="xs"
      >
        {booking}
      </TagLabel>
    </Tag>
  );
};

export const DispatchRender = ({ dispatchType }: { dispatchType: string }) => {
  const transformText = dispatchType?.toString().toUpperCase();

  const tagColorScheme =
    CASE_LISTING_TABLE_DISPATCH_COLUMN_COLOR_MAP[transformText];
  const tagIcon = CASE_LISTING_TABLE_DISPATCH_COLUMN_ICON_MAP[transformText];

  return (
    <Tag size="sm" colorScheme={tagColorScheme} borderRadius="full">
      <Box mr={1}>
        <Iconify icon={tagIcon} fontSize={16} />
      </Box>

      <TagLabel
        fontWeight="600"
        textTransform={"uppercase"}
        letterSpacing={"wide"}
        fontSize="xs"
      >
        {dispatchType}
      </TagLabel>
    </Tag>
  );
};

export const CaseStatusRender = ({
  status,
  variant,
}: {
  status: string;
  variant?: any;
}) => {
  const defaultColor = "slate.800";
  const transformText = status?.toString().toUpperCase();

  const tagColorScheme =
    CASE_LISTING_TABLE_CASE_STATUS_COLUMN_COLOR_MAP[transformText] ||
    defaultColor;
  const tagIcon = CASE_LISTING_TABLE_CASE_STATUS_COLUMN_ICON_MAP[transformText];

  return (
    <Tag
      variant={variant}
      size="sm"
      colorScheme={tagColorScheme}
      borderRadius="full"
    >
      <Box mr={1}>
        <Iconify icon={tagIcon} fontSize={16} />
      </Box>
      <TagLabel
        fontWeight="600"
        textTransform={"uppercase"}
        letterSpacing={"wide"}
        fontSize="xs"
      >
        {transformText}
      </TagLabel>
    </Tag>
  );
};

export const BillingStatusRender = ({ billTo }: { billTo: boolean }) => {
  const defaultColor = "slate.800";
  const transformText = billTo ? "BTH" : "BTP";

  const tagColorScheme =
    CASE_LISTING_TABLE_BILLING_COLUMN_COLOR_MAP[transformText] || defaultColor;
  const tagIcon = CASE_LISTING_TABLE_BILLING_COLUMN_ICON_MAP[transformText];

  return (
    <Tag size="sm" colorScheme={tagColorScheme} borderRadius="full">
      <Box mr={1}>
        <Iconify icon={tagIcon} fontSize={16} />
      </Box>
      <TagLabel
        fontWeight="600"
        textTransform={"uppercase"}
        letterSpacing={"wide"}
        fontSize="xs"
      >
        {transformText}
      </TagLabel>
    </Tag>
  );
};
