import React from "react";
import { UseQueryResult } from "react-query";
import { Box, Flex, Heading, Text, Center, Spinner } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

interface INetworkWrapper {
  status?: any;
  children: React.ReactNode;
}

export const PageLoading = () => {
  return (
    <Center h={"90vh"}>
      <Spinner size={"xl"} color="purple.500" />
    </Center>
  );
};

export default function NetworkWrapper({ status, children }: INetworkWrapper) {
  // const { isLoading, data } = status
  // const responseData: any = data

  return status?.isLoading ? <PageLoading /> : <div>{children}</div>;
}

export function NoResultFound() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Box display="inline-block">
        <Flex flexDirection="column" justifyContent="center" alignItems="center" bg={"red.500"} rounded={"50px"} w={"55px"} h={"55px"} textAlign="center">
          <CloseIcon boxSize={"20px"} color={"white"} />
        </Flex>
      </Box>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        This is the headline
      </Heading>
      <Text color={"gray.500"}>wrapper</Text>
    </Box>
  );
}
