import { useMutation, useQuery } from "react-query";
import { getFileUploadUrl, getLatestAppVersion } from "../../api/service/app";
import { getAllSource } from "api/service/mdm";
import { useRedStore } from "@store/store";
import { IFileType, IGetFileUploadBody, IPartnerList } from "@config/types";
import MiniSearch from "minisearch";
import { useEffect, useState } from "react";
import { debounce } from "lodash";

const useAppLatestVersion = () => {
  return useQuery(["latest-app-version"], getLatestAppVersion);
};

const miniSearch = new MiniSearch({
  fields: ["name"],
  storeFields: ["id", "name", "onlyForLocation", "formatted_address", "branchId", "city", "state", "postcode", "pincode", "place_id", "isPartner", "geometry", "cityCode"],
});

interface IUseSource {
  prepData?: (itm: any) => any;
}

const useSourceData = (props: IUseSource = {}) => {
  const { prepData } = props;
  const _updateSource = useRedStore(state => state._updateSource);
  const _updateSourceMap = useRedStore(state => state._updateSourceMap);
  const source = useRedStore(state => state.source);

  const [searchKey, setSearchKey] = useState(" ");
  const [filteredOptions, setFilteredOptions] = useState([]);

  const createMapFromTheSource = (data: IPartnerList[]) => {
    const map: any = {};
    for (const i of data) {
      if (i?.branchId) {
        map[i.branchId] = i;
      }
    }
    _updateSourceMap(map);
  };

  const response = useQuery(["source-data"], getAllSource, {
    onSuccess: (data: any) => {
      if (Array?.isArray(data) && data?.length > 0) {
        prepData && setFilteredOptions(prepData(data));

        _updateSource(data);
        createMapFromTheSource(data);

        const preparedPartnerListForSearch: any = data?.map((item: any, index: number) => ({
          ...item,
          id: `${item.branchId}-${index}-${Date.now()}`,
          formattedName: item.name.replace(" ", ""),
        }));
        miniSearch.addAll(preparedPartnerListForSearch);
      }
    },
    enabled: !source.length,
  });

  const updater = () => {
    if (searchKey?.length > 1) {
      const results: any = miniSearch.search(searchKey, { prefix: true });
      prepData && setFilteredOptions(prepData(results));
    } else {
      prepData && setFilteredOptions(prepData(response?.data));
    }
  };

  const refDb = debounce(updater, 100);

  useEffect(() => {
    refDb();
  }, [searchKey]);

  // Function to search
  const doSearch = (searchTerm: string) => {
    return miniSearch.search(searchTerm, { prefix: true });
  };

  return { doSearch, filteredOptions, setSearchKey, ...response };
};

const useFileUpload = () => {
  const { mutateAsync, ...rest } = useMutation(getFileUploadUrl, {
    // enabled: !!payload.city,
  });

  const getUploadLink = (file: IFileType, orderId: string, tag: string) => {
    // transform it.
    const payload: IGetFileUploadBody = {
      contentType: file.type,
      name: file.name,
      orderId,
      tag,
    };
    return mutateAsync(payload);
  };

  return { getUploadLink, ...rest };
};

export { useAppLatestVersion, useSourceData, useFileUpload };
