import { useState } from "react";
import { CLOUD_FUNCTION_BASE_URL, ENVIRONMENT } from "../axios-interceptor";
import request from "../request";
import { useQuery } from "react-query";
import { IMDMUserData } from "@config/types";
import { showErrorToast } from "@utils";

const urls = {
  cities: `/mdm-service-prod-cities`,
  clientList: "data/clients/list",
  fleetSearchUrl: "/data/fleets/search",
  getMdmUserUrl: "/data/users",
};

export const getCites = () => {
  const response = request("GET", `${urls.cities}`, {}, { baseURL: "https://asia-south1-redos-prod.cloudfunctions.net" });
  return response;
};

export const getAllSource = () => {
  const response = request("GET", `${urls.clientList}`).then((res: any) => {
    if (res?.data?.data) {
      return res?.data?.data?.map(dataModifier);
    } else {
      return [];
    }
  });
  return response;
};

export const getMdmUser = async (query?: string) => {
  const response: any = await request("GET", `${urls.getMdmUserUrl}?${query}`);
  try {
    if (!!response?.data && response?.data?.data && Array?.isArray(response?.data?.data)) {
      const myData = response?.data?.data;

      return myData?.map((itm: IMDMUserData) => {
        const mobileNumber = itm.phoneNo || itm.primaryPhoneNo;
        const creditorId = itm.walletId;
        const name = itm.name || itm.fullName || itm.email;
        return {
          label: name,
          value: {
            mobileNumber: mobileNumber,
            creditorId: creditorId,
          },
        };
      });
    } else {
      return [];
    }
  } catch (err) {
    showErrorToast("unable to extract search data.");
    return [];
  }
};

export const getMdmAmbulance = (ambId: string) => () => {
  if (!ambId) {
    throw Error("Need Ambulance ID to fetch  data.");
  }
  const url = `${urls.fleetSearchUrl}?query=${ambId}`;
  const response = request("GET", url);
  return response;
};

const dataModifier = (item: any) => {
  // bypassing the REDOS
  if (item?.name === "REDOS") return item;

  // this help to filter filed sales partner
  const isOnlyForLocation = item?.lat && item?.lng && parseInt(item?.lat) !== 0 && parseInt(item?.lng) !== 0;

  return {
    name: item?.clientName,
    onlyForLocation: isOnlyForLocation,
    formatted_address: item?.address,
    geometry: {
      location: {
        lat: item?.lat && parseFloat(item?.lat),
        lng: item?.lng && parseFloat(item?.lng),
      },
    },
    clientId: item?.masterId,
    branchId: item?.clientId,
    city: item?.city,
    state: "",
    postcode: item?.pincode,
    cityCode: item?.city,
    did: item?.did_numbers,
  };
};
