import { Spinner } from "@chakra-ui/react";
import { Suspense, lazy } from "react";

export function withLazyLoad(importFunc: any) {
  const LazyWrapper = (props: any) => {
    const LazyComponent = lazy(importFunc);

    return (
      <Suspense fallback={<Spinner size={"md"} color="red.500" />}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };

  return LazyWrapper;
}
