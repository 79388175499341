import React, { lazy, useEffect, useState } from "react";
import { useGeoTag } from "../../hooks/api/useDaasApi";
import { Box } from "@chakra-ui/react";
import NetworkWrapper from "../wrapper/NetworkWrapper";
import { getProperty } from "dot-prop";
import { withLazyLoad } from "../atom/withLazyLoad";

// const LazyApp = withLazyLoad(() => import("./GeoTagMap"));
import LazyApp from "./GeoTagMap";

export default function GeoTag({ extractValueFromCase }: any) {
  const { data, ...rest }: any = useGeoTag({
    assignmentId: extractValueFromCase("assignmentId"),
  });
  const [tags, setTags] = useState([]);

  const extractValue = (key: string) =>
    (data?.data && getProperty(data.data, key)) || "";

  useEffect(() => {
    if (data?.data) {
      const source = extractValue("source");
      const destination = extractValue("destination");
      const geoTags = extractValue("geoTags");

      const payloadArr: any = [];

      payloadArr.push({
        label: "source",
        geo: {
          lat: parseFloat(source.geo.lat),
          lng: parseFloat(source.geo.lng),
        },
        timestamp: source?.timestamp || 0,
      });
      Object.keys(geoTags).forEach((itm) => {
        const payload = {
          label: itm,
          geo: {
            lat: geoTags[itm].lat,
            lng: geoTags[itm].lng,
          },
          timestamp: geoTags[itm]?.timestamp || 0,
        };
        payloadArr.push(payload);
      });
      payloadArr.push({
        label: "destination",
        geo: {
          lat: parseFloat(destination.geo.lat),
          lng: parseFloat(destination.geo.lng),
        },
        timestamp: destination?.timestamp || 0,
      });

      setTags(payloadArr);
    }
  }, [data?.data]);

  return (
    <NetworkWrapper status={rest}>
      <Box>{tags?.length > 0 && <LazyApp data={tags} />}</Box>
    </NetworkWrapper>
  );
}
