import { AMBULANCE_MAPPER_KEY, HM_CAN_DISPATCH_SERVICE_TYPE, PLATFORM_OBJ, SERVICE_TYPE_MAP, VEHICLE_TYPE, X_DISTANCE } from "@config/constant";
import { WaypointsEntity } from "@config/types";
import { OVERRIDDEN_SERVICE_TYPE } from "@hooks/api/useSave";
import { useRedStore } from "@store/store";
import { showErrorToast } from "@utils";
import { setProperty } from "dot-prop";
import { shallow } from "zustand/shallow";
import { version } from "../../../package.json";
import { find } from "lodash";
import { TLocationType } from "@store/slice/order.slice";

// This is a hook file which is between UI and Store + Network.
/**
 *
 * Case 1: User just update the store
 * Case 2: User update and make the API Call -> API call resolve and update the store
 *
 * So this action hook can do both
 */

export default function useOrderAction() {
  const user = useRedStore(state => state.user);
  const originSource = useRedStore(state => state.localOrderChanges?.originSource);
  const data = useRedStore(state => state.source);
  const saatiPricingList = useRedStore(state => state.saatiPricingList);

  const _updateBulkLocalOrderChanges = useRedStore(state => state._updateBulkLocalOrderChanges);
  const _updateBulkOrderChanges = useRedStore(state => state._updateBulkOrderChanges);

  const _updateLocationType = useRedStore(state => state._updateLocationType);
  const _updateLocalCaseProperty = useRedStore(state => state._updateLocalCaseProperty);
  const localOrderChanges = useRedStore(state => state.localOrderChanges);

  const _updateWaypoints = useRedStore(state => state._updateWaypoints);
  const _updateServiceLocation = useRedStore(state => state._updateServiceLocation);
  const _updateWaypoints1 = useRedStore(state => state._updateWaypoint1);
  const _updateWaypoints2 = useRedStore(state => state._updateWaypoint2);
  const _updateMedicalEmergency = useRedStore(state => state._updateMedicalEmergency);
  const _updateAge = useRedStore(state => state._updateAge);
  const _updateGender = useRedStore(state => state._updateGender);
  const _updatePatientName = useRedStore(state => state._updatePatientName);
  const _updatePatientHeight = useRedStore(state => state._updatePatientHeight);
  const _updatePatientWeight = useRedStore(state => state._updatePatientWeight);
  const _updateTemplateType = useRedStore(state => state._updateTemplateType);
  const _updateVehicles = useRedStore(state => state._updateVehicles);
  const _resetVehicleInfo = useRedStore(state => state._resetVehicleInfo);
  const _updateServiceLocationDuration = useRedStore(state => state._updateServiceLocationDuration);
  const _updateRequestedInfo = useRedStore(state => state._updateRequestedInfo);
  const _updateRequestFor = useRedStore(state => state._updateRequestFor, shallow);
  const _updateOverwriteFare = useRedStore(state => state._updateOverwriteFare);
  const _updateSelectedOrder = useRedStore(state => state._updateSelectedOrder);
  const _setConstToOwner = useRedStore(state => state._setConstToOwner);

  const _resetLocalOrderChanges = useRedStore(state => state._resetLocalOrderChanges);

  const __updateMultipleBulk = (payload: any) => {
    _updateBulkLocalOrderChanges(payload);
  };

  const __updateSelectedOrder = (order: any) => {
    _updateSelectedOrder(order);
  };

  const getHmSource = () => {
    const source = user?.dedicatedToClientId;
    const originSource: any = data && data?.find((itm: any) => itm.branchId === source);
    return originSource;
  };

  const isServiceBlockValid = () => {
    return !!localOrderChanges.city && !!localOrderChanges.originSource.source && !!localOrderChanges.attributedTo;
  };

  const isPatientDetailsValid = () => {
    return (
      localOrderChanges.requestedFor?.medicalIssue?.reason?.length > 0 && !!localOrderChanges?.requestedFor.age && !!localOrderChanges?.requestedFor.name && !!localOrderChanges?.requestedFor.gender
    );
  };

  const populateLocalState = (apiData: any) => {
    let payload: any = {
      orderId: apiData?.orderId,
    };

    const updateSourceSectionToPayload = () => {
      const cityCode = apiData?.city;
      const branchId = apiData?.originSource?.source;
      const platform = apiData?.originSource?.platform || PLATFORM_OBJ.platform;
      const version = apiData?.originSource?.version || PLATFORM_OBJ.version;

      payload.originSource = {
        platform,
        version,
        source: branchId || "",
      };
      payload.city = cityCode || "";
      payload.department = apiData?.department;

      // Initial State
      if (!branchId || !cityCode) {
        const hmInfo = getHmSource();

        if (!branchId) payload.originSource.source = hmInfo?.branchId;
        if (!cityCode) payload.city = hmInfo?.cityCode;
      }
      payload.attributedTo = apiData?.attributedTo || "RED";

      try {
        const serviceType = SERVICE_TYPE_MAP[apiData?.serviceType];

        if (serviceType) {
          payload.serviceType = serviceType.value;
          // Apply Service Type Effect
          if (serviceType?.effect) {
            payload = {
              ...payload,
              ...serviceType.effect,
            };
          }
        }
      } catch (error) {
        console.error("Not able to update fetched service type from api.: ", error);
        showErrorToast(`Unable to find Service Type. ${apiData?.serviceType}`);
      }

      payload._isServiceSectionValid = !!payload.serviceType && !!payload.city && !!payload.originSource.source && !!payload.attributedTo && !!payload.department;
    };

    const updateDispatchTypeToPayload = () => {
      apiData?.dispatchType && setProperty(payload, "dispatchType", apiData?.dispatchType);
      setProperty(payload, "scheduledDdtm", apiData?.scheduledDdtm);
      setProperty(payload, "_isDispatchModeValid", !!apiData?.dispatchType);
      setProperty(payload, "bookingType", apiData?.bookingType);
    };

    const updateWaypointsToPayload = () => {
      setProperty(payload, "distance", apiData?.distance);
      setProperty(payload, "waypoints", apiData?.waypoints || [{}, {}]);
      const _isWaypointValid = !!apiData?.waypoints && Array.isArray(apiData?.waypoints) && apiData?.waypoints.length > 1 && !!apiData?.waypoints[0]?.address && !!apiData?.waypoints[1]?.address;
      setProperty(payload, "_isWaypointValid", _isWaypointValid);
    };

    const updatePatientDetailsToPayload = () => {
      const userReason = apiData?.requestedFor?.medicalIssue?.reason;

      const reason = Array.isArray(userReason) ? [...(userReason && userReason)]?.map((itm: any) => ({ label: itm, value: itm })) : [];
      if (reason.length > 0) {
        setProperty(payload, "requestedFor.medicalIssue.reason", reason);
      }

      const gender = apiData?.requestedFor?.gender;
      if (gender) {
        setProperty(payload, "requestedFor.gender", { label: gender, value: gender });
      }

      setProperty(payload, "requestedBy.name", apiData?.requestedBy?.name);
      setProperty(payload, "requestedBy.mobile", apiData?.requestedBy?.mobile);

      setProperty(payload, "requestedFor.name", apiData?.requestedFor?.name);
      setProperty(payload, "requestedFor.height", apiData?.requestedFor?.height);
      setProperty(payload, "requestedFor.weight", apiData?.requestedFor?.weight);
      setProperty(payload, "requestedFor.mobile", apiData?.requestedFor?.mobile);
      setProperty(payload, "requestedFor.age", apiData?.requestedFor?.age);

      const _isPatientDetailsValid = !!gender && !!reason && !!apiData?.requestedFor?.age && !!apiData?.requestedFor?.name;
      setProperty(payload, "_isPatientDetailsValid", _isPatientDetailsValid);
      setProperty(payload, "_isAddonValid", payload.dispatchType === "SCHEDULED");
    };

    const updateLamaDamaAndCovid = () => {
      setProperty(payload, "systemOrderClassification", apiData?.systemOrderClassification);
      setProperty(payload, "isLamaDamaSelected", apiData?.isLamaDamaSelected);
      setProperty(payload, "isCovidCase", apiData?.isCovidCase);
    };

    const updateAmbulanceData = () => {
      const subtype = apiData?.entityRequired && apiData?.entityRequired[0]?.subtype;

      if (!subtype) {
        setProperty(payload, "entityRequired", []);
        return;
      }

      const allVariants = VEHICLE_TYPE?.reduce((a: any, b: any) => {
        return [...a, ...(b.variant || [])];
      }, []);

      const val = allVariants.find(itm => itm.value === subtype);

      const vehicleType = VEHICLE_TYPE?.find(itm => itm?.value === val?.parent);

      const el = [
        {
          ...apiData?.entityRequired[0],
          subtype: val?.value,
        },
      ];

      setProperty(payload, "_selectedVehicleType", vehicleType);
      setProperty(payload, "entityRequired", el);
      setProperty(payload, "_isVehicleDetailsValid", !!vehicleType);
    };

    const updateOverrideSection = () => {
      setProperty(payload, "fareAgreed", apiData?.fareAgreed);
      setProperty(payload, "overridenComments", apiData?.overridenComments);
      if (apiData?.overridenType) {
        setProperty(payload, "overwriteBillTo", apiData?.overridenType === "BILL_TO_PATIENT" ? "BTP" : "BTC");
      }
      setProperty(payload, "isOverridenPrice", apiData?.isOverridenPrice);
      if (apiData?.isOverridenPrice || OVERRIDDEN_SERVICE_TYPE.includes(apiData?.serviceType)) {
        setProperty(payload, "overwriteFare", apiData?.fareAgreed);
        if (!apiData?.overridenComments) setProperty(payload, "overridenComments", apiData?.serviceType);
        setProperty(payload, "isOverridenPrice", true);
        // setProperty(payload, "isOverridenPrice", true);
      }
    };

    const updateOtherInfo = () => {
      setProperty(payload, "requestedBy", apiData?.requestedBy);
      setProperty(payload, "_needAdvancePayment", !apiData?.paymentDetails?.event);
      setProperty(payload, "_is_more_then_x_km", apiData?.distance && parseInt(apiData.distance?.toString()) > X_DISTANCE);
      setProperty(payload, "_hm_can_assign", HM_CAN_DISPATCH_SERVICE_TYPE.includes(apiData?.serviceType));
      setProperty(payload, "version", version);
    };

    updateSourceSectionToPayload();
    updateDispatchTypeToPayload();
    updateWaypointsToPayload();
    updatePatientDetailsToPayload();
    updateLamaDamaAndCovid();
    updateAmbulanceData();
    updateOverrideSection();
    updateOtherInfo();

    _updateBulkLocalOrderChanges(payload);
  };

  const __updateRequestedFor = (payload: any) => {
    _updateRequestFor(payload);
  };

  const __updateSource = (branchId?: string, cityCode?: string) => {
    const platform = originSource?.platform || PLATFORM_OBJ.platform;
    const version = originSource?.version || PLATFORM_OBJ.version;

    const payload = {
      originSource: {
        source: branchId || "",
        version: version,
        platform,
      },
      city: cityCode || "",
    };

    if (!branchId || !cityCode) {
      const hmInfo = getHmSource();

      if (!branchId) payload.originSource.source = hmInfo?.branchId;
      if (!cityCode) payload.city = hmInfo?.cityCode;
    }

    payload?.originSource?.source && payload?.city && _updateBulkLocalOrderChanges(payload);
  };

  const __updateAttribute = (value: string) => {
    _updateLocalCaseProperty("attributedTo", value || "RED");
  };

  const __updateWaypointsToPayload = (apiData: any) => {
    const payload = {};
    setProperty(payload, "distance", apiData?.distance);
    setProperty(payload, "waypoints", apiData?.waypoints || [{}, {}]);
    const _isWaypointValid = !!apiData?.waypoints && Array.isArray(apiData?.waypoints) && apiData?.waypoints.length > 1 && !!apiData?.waypoints[0]?.address && !!apiData?.waypoints[1]?.address;
    setProperty(payload, "_isWaypointValid", _isWaypointValid);
    _updateBulkLocalOrderChanges(payload);
  };

  const __updateCity = (value: string) => {
    _updateLocalCaseProperty("city", value);
  };

  const __updateDepartment = (value: string) => {
    _updateLocalCaseProperty("department", value);
  };

  const __updateService = (value: string) => {
    try {
      const serviceType = SERVICE_TYPE_MAP[value];
      const payload = {
        ...(serviceType?.effect && serviceType.effect),
        serviceType: serviceType.value,
        _isServiceSectionValid: isServiceBlockValid(),
      };
      _updateBulkLocalOrderChanges(payload);
    } catch (error) {
      console.error("Not able to update service type: ", error);
      showErrorToast(`Unable to find Service Type. ${value}`);
    }
  };

  const __updateDispatchType = (value: string) => {
    const payload: any = {};
    if (value === "NOW") {
      payload.dispatchType = "NOW";
      // payload.scheduledDdtm = 0;
      payload.addonsFare = 0;
      payload.addons = [];
      payload._isDispatchModeValid = true;
      payload._isAddonValid = false;
    } else {
      payload.dispatchType = "SCHEDULED";
      payload._isAddonValid = true;
    }
    _updateBulkLocalOrderChanges(payload);
  };

  const __updateScheduledDdtm = (value: any) => {
    _updateBulkLocalOrderChanges({ scheduledDdtm: value, _isDispatchModeValid: true });
  };

  const __updateWaypoints = (waypoint1?: WaypointsEntity | null, waypoint2?: WaypointsEntity | null) => {
    const _way1: any = waypoint1 || (localOrderChanges?.waypoints && localOrderChanges?.waypoints?.length > 0 && localOrderChanges?.waypoints[0]);
    const _way2: any = waypoint2 || (localOrderChanges?.waypoints && localOrderChanges?.waypoints?.length > 1 && localOrderChanges?.waypoints[1]);

    if (waypoint1 && waypoint2) {
      _updateWaypoints(waypoint1, waypoint2);
      _updateLocalCaseProperty("_isWaypointValid", true);
      return;
    } else if (!waypoint1 && waypoint2) {
      _updateWaypoints2(waypoint2);
      _updateLocalCaseProperty("_isWaypointValid", _way1.address);
      return;
    } else if (waypoint1 && !waypoint2) {
      _updateWaypoints1(waypoint1);
      _updateLocalCaseProperty("_isWaypointValid", _way2.address);
      return;
    }
  };

  const __updateServiceLocation = (serviceLocation?: WaypointsEntity | null) => {
    const _way1: any = serviceLocation;

    // || (localOrderChanges?.serviceLocation && localOrderChanges?.serviceLocation?.length > 0 && localOrderChanges?.serviceLocation[0]);

    if (_way1) {
      _updateServiceLocation(_way1);
      return;
    }
  };

  const __updateMedicalEmergency = (reasons: object[]) => {
    _updateMedicalEmergency(reasons);
    _updateLocalCaseProperty("_isPatientDetailsValid", isPatientDetailsValid());
  };

  const __updateAge = (age: number) => {
    _updateAge(age);
    _updateLocalCaseProperty("_isPatientDetailsValid", isPatientDetailsValid());
  };
  const __updatePatientName = (name: string) => {
    _updatePatientName(name);
    _updateLocalCaseProperty("_isPatientDetailsValid", isPatientDetailsValid());
  };
  const __updatePatientWeight = (weight: number) => {
    _updatePatientWeight(weight);
  };
  const __updatePatientHeight = (payload: { feet: number; inches: number }) => {
    _updatePatientHeight(payload);
    _updateLocalCaseProperty("_isPatientDetailsValid", isPatientDetailsValid());
  };

  const __updateRequestedInfo = (reqFor: any, reqBy: any) => {
    _updateRequestedInfo(reqFor, reqBy);
  };

  const __updateGender = (gender: object) => {
    _updateGender(gender);
    _updateLocalCaseProperty("_isPatientDetailsValid", isPatientDetailsValid());
  };

  const __updateLamadama = (state: boolean) => {
    _updateLocalCaseProperty("isLamaDamaSelected", state);
  };

  const __updateCovidSwitch = (state: boolean) => {
    _updateLocalCaseProperty("isCovidCase", state);
  };

  const __updateCaseOverride = (state: boolean) => {
    _updateLocalCaseProperty("isOverridenPrice", state);
  };

  const __resetOrderStore = () => {
    _resetLocalOrderChanges();
  };

  const __updateVehicleVariant = (obj: any) => {
    const { variant, price } = obj;

    if (variant && saatiPricingList && Array.isArray(saatiPricingList)) {
      const subtype = AMBULANCE_MAPPER_KEY[variant];
      const selectedVehicle: any = find(saatiPricingList, ["fleetVariant", subtype]);
      if (!!selectedVehicle && selectedVehicle?.costToOwner) {
        _setConstToOwner(selectedVehicle?.costToOwner);
      }
    }

    const payload: any = {
      _isAddonValid: !!variant && localOrderChanges.dispatchType === "SCHEDULED",
      entityRequired: [
        {
          partnerLevel: "ONE_P",
          type: "FLEET",
          subtype: variant,
          quantity: 1,
        },
      ],
    };
    if (price) {
      payload["fareAgreed"] = price;
    }
    _updateBulkLocalOrderChanges(payload);
  };

  const __updateSelectedVariant = (obj: any) => {
    _updateLocalCaseProperty("_selectedVehicleType", obj);
  };

  const __updateCaseDistance = (distance: any) => {
    _updateBulkLocalOrderChanges({ distance: distance || 0 });
  };

  const __updateCaseTypeAndClassification = (caseType: any) => {
    const payload: any = {
      caseType,
      orderClassification: caseType,
    };

    _updateBulkLocalOrderChanges(payload);
  };

  const __updateFearAgreed = (fare: number | null, billToClient?: boolean, distance?: any) => {
    const payload: any = {
      fareAgreed: fare || 0,
    };
    if (billToClient !== undefined && billToClient !== null) {
      payload["billToClient"] = billToClient;
    }

    if (distance) {
      payload["distance"] = distance;
    }

    _updateBulkLocalOrderChanges(payload);
  };

  const __updateOverrideComments = (comments: string) => {
    _updateLocalCaseProperty("overridenComments", comments);
  };

  const __updateFareAgreedLocal = (obt: number) => {
    _updateLocalCaseProperty("fareAgreed", obt);
  };

  const __updateOverrideBillTo = (obt: string) => {
    _updateLocalCaseProperty("overwriteBillTo", obt);
  };

  const __updateOverridePriceTo = (price: number) => {
    _updateOverwriteFare(price);
  };

  const __updateTemplateType = (type: string) => {
    _updateTemplateType(type);
  };

  const __updateNearbyVehicle = (obj: any) => {
    _updateVehicles(obj);
  };

  const __resetVehicleInfo = () => {
    _resetVehicleInfo();
  };

  const __updateOrderWaypointAndSourceData = (payload: any) => {
    _updateBulkOrderChanges(payload);
  };

  const __updateServiceLocationDuration = (payload: any) => {
    _updateServiceLocationDuration(payload);
  };

  const __updateLocationType = (obj: any) => {
    _updateLocationType(obj);
  };

  return {
    __updateLocationType,
    __updateOrderWaypointAndSourceData,
    __updateFearAgreed,
    __updateOverrideComments,
    __updateCaseTypeAndClassification,
    __updateCaseDistance,
    __updateCaseOverride,
    __updateSelectedVariant,
    __updateLamadama,
    __updateVehicleVariant,
    __updateAge,
    __updatePatientName,
    __updateGender,
    __updateSource,
    __updateAttribute,
    __updateCity,
    __updateService,
    __updateDepartment,
    __updateDispatchType,
    __updateScheduledDdtm,
    __updateWaypoints,
    populateLocalState,
    __updateMedicalEmergency,
    __updateCovidSwitch,
    __updateOverridePriceTo,
    __updateOverrideBillTo,
    __resetOrderStore,
    __updateTemplateType,
    __updateNearbyVehicle,
    __resetVehicleInfo,
    __updateMultipleBulk,
    __updateServiceLocation,
    __updatePatientHeight,
    __updatePatientWeight,
    __updateServiceLocationDuration,
    __updateRequestedInfo,
    __updateRequestedFor,
    __updateFareAgreedLocal,
    __updateWaypointsToPayload,
    __updateSelectedOrder,
  };
}
