import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Text, Center, Stack, Box, Flex, Badge } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";

type Props = {
  requestedAmbulance: string[];
  actualAmbulanceType: string;
};

export default function AmbulanceCanServeAsAmbulanceRequested({ requestedAmbulance, actualAmbulanceType }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Text onClick={onOpen} color={"blue.500"} fontWeight={"semibold"} fontSize={"sm"}>
        See details
      </Text>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx={5}>
          <ModalHeader>Ambulance Can Serve</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack pb={10} gap={5}>
              <Box>
                <Text>Requested Ambulance Type:</Text>
                <Flex flexWrap={"wrap"} gap={2} py={2}>
                  {requestedAmbulance?.map(itm => (
                    <Badge variant={"outline"} fontSize={14} colorScheme="red" px={2} borderRadius={"full"}>
                      {itm.toString()?.replace("_", " ")}
                    </Badge>
                  ))}
                </Flex>
              </Box>

              <Box>
                <Text>Actual Ambulance Type:</Text>
                <Badge variant={"solid"} fontSize={14} colorScheme="green" px={2} borderRadius={"full"}>
                  {actualAmbulanceType?.toString()?.replace("_", " ")}
                </Badge>
              </Box>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
