import { Box, Divider, Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import NetworkWrapper from "../wrapper/NetworkWrapper";
import dayjs from "dayjs";
import { Icon } from "@iconify/react";
import { useCaseTransactionsData } from "../../hooks/api/useWalletApi";
import { useState } from "react";
import { useRedStore } from "@store/store";
import { getProperty } from "dot-prop";

const TransactionComponent = () => {
  const order = useRedStore(state => state.order);
  const darkSlate = useColorModeValue("slate.300", "slate.800");
  const cardSlateBg = useColorModeValue("white", "slate.800");

  const [transactions, setTransactions] = useState([]);

  const { ...rest }: any = useCaseTransactionsData({
    orderId: order?.orderId,
    onSuccess: (data: any) => {
      if (data && data?.data && data.data?.length > 0) {
        setTransactions(data.data);
      }
    },
  });

  const extractValueFromCase: any = (key: string) => getProperty(order, key);

  const amountPaid = transactions?.length > 0 ? transactions?.reduce((a, b: any) => a + +b?.amount || 0, 0) : 0;

  return (
    <NetworkWrapper status={rest}>
      <Box>
        <Flex align="flex-end" justify="space-between">
          <Text fontSize="xs" color="gray">
            Case ID
          </Text>
          <Text fontSize="xs" fontWeight="semibold">
            {extractValueFromCase("orderId")}
          </Text>
        </Flex>
        <Box mt="2" />
        <Flex align="flex-end" justify="space-between">
          <Text fontSize="xs" color="gray">
            Patient Name
          </Text>
          <Text fontSize="xs" fontWeight="semibold">
            {extractValueFromCase("requestedFor.name")}
          </Text>
        </Flex>
        <Flex align="flex-end" justify="space-between" mt={2}>
          <Text fontSize="xs" color="gray">
            Case Created On
          </Text>
          <Text fontSize="xs" fontWeight="semibold">
            {dayjs.unix(extractValueFromCase("createdAt") / 1000)?.format("DD MMM YYYY, HH:mm A")}
          </Text>
        </Flex>
        <Divider my="3" />

        <Flex align="flex-end" justify="space-between">
          <Text fontWeight="normal" fontSize={"xs"}>
            Total Amount Payable
          </Text>
          <Text fontWeight="semibold" color="blackAlpha.900" fontSize={"xs"}>
            ₹ {extractValueFromCase("fareAgreed") / 100}
          </Text>
        </Flex>
        <Box mt="2" />
        <Flex align="flex-end" justify="space-between">
          <Text fontWeight="normal" fontSize={"xs"}>
            Amount Paid
          </Text>
          <Text fontWeight="semibold" color="blackAlpha.900" fontSize={"xs"}>
            ₹ {amountPaid / 100}
          </Text>
        </Flex>
        <Divider my="3" />
        <Flex align="flex-end" justify="space-between">
          <Text fontWeight="semibold" fontSize={"sm"}>
            Amount to Be Paid
          </Text>
          <Text fontWeight="semibold" color="red">
            ₹ {(extractValueFromCase("fareAgreed") - amountPaid) / 100}
          </Text>
        </Flex>
        <Box py="1" bg={darkSlate} borderWidth="px" mb="4" mt="8" borderRadius="md">
          <Text color="gray" align="center" fontWeight="semibold">
            Case Transactions
          </Text>
        </Box>
        <Flex align="flex-end" justify="space-between">
          <Text color="gray" fontSize="xs">
            Transaction Details
          </Text>
          <Text color="gray" fontSize="xs">
            Amount
          </Text>
        </Flex>
        <Stack mb={5} gap={0.5}>
          {transactions.map((item: any, index: number) => (
            <Flex key={index} align="flex-start" justify="space-between" bg={cardSlateBg} px={2} pb={1} borderRadius={"md"} shadow={"md"} my={2}>
              <Box mt="2">
                <Text fontSize="sm" fontWeight="semibold" mb={1}>
                  {item.paymentMode}
                </Text>
                <Text fontSize="xs">{dayjs.unix(item.date / 1000).format("DD MMM YYYY, HH:mm A")}</Text>
                <Text fontSize="xs">Transaction ID: {item.transactionId}</Text>
              </Box>
              <Box mt="2">
                <Flex>
                  <Box mr="2">
                    <Icon icon="mdi:cash" fontSize={"20"} />
                  </Box>
                  <Text fontSize="sm" color="green.500" fontWeight="semibold">
                    ₹ {item.amount / 100}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          ))}
        </Stack>
        {transactions.length === 0 && (
          <Box mt="4">
            <Text align="center" color="gray">
              No transactions found
            </Text>
          </Box>
        )}
      </Box>
    </NetworkWrapper>
  );
};

export default TransactionComponent;
