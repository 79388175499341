import {
  useState,
  useEffect,
  forwardRef,
  memo,
  useRef,
  useImperativeHandle,
} from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  Button,
  Box,
  IconButton,
  useMediaQuery,
  Wrap,
  Center,
  Tooltip,
  Stack,
  Skeleton,
  InputGroup,
  Input,
  Tag,
  useDisclosure,
  TagLabel,
  TagCloseButton,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeader,
  DrawerFooter,
  DrawerContent,
  Divider,
  DrawerOverlay,
  Text,
  Flex,
  WrapItem,
} from "@chakra-ui/react";
import dayjs from "../atom/dayjs";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Icon } from "@iconify/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUrlParams } from "../../hooks/action/useUtils";
import {
  convertPrice,
  objectToQueryString,
  objectToQueryStringOmitEmpty,
} from "../../utils";
import { useFiltersStore } from "../../store/FIlterStore";
import DatePicker from "react-datepicker";
import Select, { Props } from "react-select";
import { getProperty } from "dot-prop";

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  isDataLoading: boolean;
};

const MemoizedRow = memo(function Row({ row }: { row: any }) {
  const navigate = useNavigate();
  const pageName = window.location.pathname.split("/")?.pop();
  const borderColor = useColorModeValue("slate.200", "slate.700");

  const onRowClick = () => {
    if (pageName === "case-list" && row?.original?.orderId) {
      navigate(`/case/${row?.original?.orderId}`);
    } else if (pageName === "wallet-details" && row?.original?.walletId) {
      const parser = (key: string) => getProperty(row.original, key);
      const payload = {
        balance: convertPrice(parser("balance") || 0),
        fullName: parser("userDetails.fullName"),
        username: parser("userDetails.userName"),
        userType: parser("userDetails.userType"),
        role: parser("userDetails.role"),
      };
      navigate(
        `/wallet/${row?.original?.walletId}${objectToQueryStringOmitEmpty(
          payload
        )}`
      );
    }
  };
  return (
    <Tr key={row.id} onClick={onRowClick}>
      {row.getVisibleCells().map((cell: any) => {
        const meta: any = cell.column.columnDef.meta;
        return (
          <Td
            key={cell.id}
            isNumeric={meta?.isNumeric}
            fontSize="sm"
            color="gray.800"
            border="0.10px solid"
            borderColor={borderColor}
            height={10}
            _last={{ borderBottom: "none" }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
    </Tr>
  );
});

interface IOptions {
  label: string;
  icon: string;
  identifier: string;
}
export interface IFilter {
  label: string;
  identifier: string;
  filters: IOptions[];
}

export interface ITableDropdown {
  placeholder: string;
  clearable?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  options: { value: string; label: string }[];
  onChange: (e: any) => void;
}

interface ITableHeader {
  filters?: IFilter[];
  id?: string;
  valueOfId?: string;
  dropdown1?: Props<any>;
  dropdown2?: Props<any>;
}

export const TableHeader = ({
  filters,
  id,
  valueOfId = "",
  dropdown1,
  dropdown2,
}: ITableHeader) => {
  const navigate = useNavigate();

  const [, setSearchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bg = useColorModeValue("white", "slate.800");

  const [searchField, setSearchField] = useState(valueOfId);

  const onSearchClick = (searchId: string) => {
    if (id) {
      const query = objectToQueryString({ [id]: searchId });
      setSearchParams(query);
      navigate({
        pathname: window.location.pathname,
        search: query,
      });
    }
  };

  return (
    <Box minH={16} w={"full"} bg={bg} borderRadius={"md"}>
      <Flex w={"full"} align={"center"} px={4} py={3} justify={"space-between"}>
        {id && (
          <Wrap>
            <WrapItem w={["full", 300]}>
              <InputGroup>
                <Input
                  value={searchField}
                  type="tel"
                  placeholder="Case ID"
                  pr={10}
                  onChange={(e) => setSearchField(e.target.value)}
                />
                <IconButton
                  borderRadius={"md"}
                  variant="solid"
                  zIndex={100}
                  onClick={() => onSearchClick(searchField)}
                  aria-label="open menu"
                  size={"sm"}
                  pos={"absolute"}
                  right={1}
                  top={1}
                  _hover={{ cursor: "pointer", bg: "black" }}
                  icon={
                    <Center>
                      <Icon icon={"iconamoon:search-bold"} fontSize={16} />
                    </Center>
                  }
                />
              </InputGroup>
            </WrapItem>
            <WrapItem>
              {dropdown1 && (
                <Box minW={"10vw"} maxW={200} mr={3}>
                  <Select
                    {...dropdown1}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        fontSize: 12,
                        fontWeight: 700,
                      }),
                      option: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: 12,
                        fontWeight: 500,
                        letterSpacing: "0.02em",
                        textTransform: "capitalize",
                        backgroundColor: "white",
                        color: "black",
                      }),
                    }}
                  />{" "}
                </Box>
              )}
              {dropdown2 && (
                <Box minW={"10vw"}>
                  <Select
                    {...dropdown2}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        fontSize: 12,
                        fontWeight: 700,
                      }),
                      option: (base) => ({
                        ...base,
                        zIndex: 9999,
                        fontSize: 12,
                        fontWeight: 500,
                        letterSpacing: "0.02em",
                        textTransform: "capitalize",
                        backgroundColor: "white",
                        color: "black",
                      }),
                    }}
                  />
                </Box>
              )}
            </WrapItem>
          </Wrap>
        )}
        {filters && (
          <Flex>
            <IconButton
              borderRadius={"md"}
              variant="outline"
              onClick={onOpen}
              aria-label="open menu"
              size={"md"}
              _hover={{
                cursor: "pointer",
                bg: "primary.600",
                color: "primary.100",
              }}
              mx={4}
              icon={
                <Center>
                  <Icon icon={"mdi:filter"} fontSize={16} />
                </Center>
              }
            />
          </Flex>
        )}
      </Flex>
      {filters && (
        <FilterDrawer isOpen={isOpen} onClose={onClose} filters={filters} />
      )}
    </Box>
  );
};

const FilterDrawer = ({ isOpen, onClose, filters }: any) => {
  const [isMobileView] = useMediaQuery("(max-width: 768px)");

  const applyRef: any = useRef();
  const closeRef: any = useRef();

  const onCloseClick = () => {
    closeRef.current && closeRef?.current?.clearFilters();
    onClose();
  };

  const onOpenClick = () => {
    applyRef.current && applyRef?.current?.updateFilerParams();
    onClose();
  };

  const getRef = () => ({ applyRef, closeRef });

  return (
    <Drawer
      isOpen={isOpen}
      placement={isMobileView ? "bottom" : "right"}
      onClose={onCloseClick}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Filter</DrawerHeader>
        <Divider />
        <DrawerBody>
          <Filters
            filters={filters}
            applyRef={applyRef}
            closeRef={closeRef}
            ref={getRef}
          />
        </DrawerBody>
        <Divider />
        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onCloseClick}>
            Clear Filter
          </Button>
          <Button colorScheme="primary" onClick={onOpenClick}>
            Apply Filter
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

const Filters = forwardRef((props: any, ref: any) => {
  const navigate = useNavigate();
  const { setActiveFilters, selectedFilter } = useFiltersStore();
  const [activeParams, setActiveParams] = useState({});
  const { getAllParams } = useUrlParams();
  const [, setSearchParams] = useSearchParams();
  const { filters } = props;

  const { applyRef, closeRef } = ref();

  useEffect(() => {
    const allParams = getAllParams();
    setActiveFilters(allParams);
    setActiveParams(allParams);
    // eslint-disable-next-line no-console
    console.log(allParams, activeParams);
  }, []);

  // ?: This function take a query and reload browser
  const setUrlAndRefresh = (queryString: string) => {
    setSearchParams(queryString);
    navigate({
      pathname: window.location.pathname,
      search: queryString,
    });
    // window.location.reload()
  };

  useImperativeHandle(applyRef, () => ({
    updateFilerParams() {
      if (selectedFilter?.page) {
        delete selectedFilter.page;
      }
      const queryString = objectToQueryString(selectedFilter);
      setUrlAndRefresh(queryString);
    },
  }));

  useImperativeHandle(closeRef, () => ({
    clearFilters() {
      setActiveFilters({});
      setUrlAndRefresh("");
    },
  }));

  return (
    <Box>
      <Box mb="32">
        {filters.map((itm: IFilterGroup) => (
          <FilterGroup key={itm.identifier} {...itm} />
        ))}
      </Box>
    </Box>
  );
});

function convertEpoch(epoch?: string) {
  return !epoch ? dayjs() : dayjs(dayjs.unix(parseInt(epoch) / 1000));
}

export const RangePicker = ({
  handleOnSelect,
  defaultStartDate,
  defaultEndDate,
  inline = true,
}: any) => {
  const { getParams } = useUrlParams();

  const urlStartDate = getParams("startDate") || defaultStartDate || undefined;
  const urlEndDate = getParams("endDate") || defaultEndDate || undefined;

  const [startDate, setStartDate] = useState(
    convertEpoch(urlStartDate)?.toDate() || dayjs().subtract(1, "days").toDate()
  );
  const [endDate, setEndDate] = useState(
    convertEpoch(urlEndDate)?.toDate() || dayjs().toDate()
  );

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (!start || !end) {
      return;
    }

    const startDate = Math.floor(
      new Date(dayjs(start)?.startOf("day").toISOString())?.getTime()
    );
    const endDate = Math.floor(
      new Date(dayjs(end)?.startOf("day").toISOString())?.getTime()
    );

    handleOnSelect({ startDate, endDate });
  };

  return inline ? (
    <DatePicker
      startDate={startDate}
      endDate={endDate}
      onChange={onChange}
      selected={startDate}
      selectsRange
      inline
      maxDate={dayjs().toDate()}
    />
  ) : (
    <Box
      bg={"white"}
      borderWidth={1}
      borderColor={"gray.300"}
      justifyContent={"center"}
      alignItems={"center"}
      pt={2}
      borderRadius={"md"}
      ml={2}
      fontSize={"sm"}
      fontWeight={"semibold"}
      px={2}
    >
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        selected={startDate}
        selectsRange
        maxDate={dayjs().toDate()}
      />
    </Box>
  );
};
export interface IFilterItem {
  label: string;
  icon?: string;
  identifier: string;
  groupIdentifier: string;
}
export interface IFilterGroup {
  label: string;
  filters: IFilterItem[];
  identifier: string;
}

const FilterGroup = ({ label, filters, identifier }: IFilterGroup) => {
  return (
    <Box mb="6">
      <Box my="2">
        <Text fontSize="xs">{label}</Text>
      </Box>
      <Flex wrap="wrap">
        {filters.map((itm, index) => (
          <FilterItem key={index} {...itm} groupIdentifier={identifier} />
        ))}
      </Flex>
    </Box>
  );
};
export interface IFilterItem {
  label: string;
  icon?: string;
  identifier: string;
  groupIdentifier: string;
}

export const FilterItem = ({
  label,
  identifier,
  groupIdentifier,
}: IFilterItem) => {
  const { selectedFilter, toggleActive, injectFilter } = useFiltersStore();
  const [, setSearchParams] = useSearchParams();

  const selectedOption = selectedFilter[groupIdentifier]
    ?.toString()
    .toLocaleLowerCase();
  const isSelected =
    selectedOption?.toString().toLocaleLowerCase() ===
    identifier?.toString().toLocaleLowerCase();

  const handleOnclick = (state: string | number) => {
    toggleActive(groupIdentifier, state);

    if (identifier === "last_week") {
      const startDate = Math.floor(
        new Date(
          dayjs().startOf("day").subtract(7, "days")?.toISOString()
        )?.getTime()
      );
      const endDate = Math.floor(
        new Date(dayjs()?.startOf("day")?.toISOString())?.getTime()
      );
      injectFilter({ startDate, endDate });
    } else if (identifier === "yesterday") {
      const startDate = Math.floor(
        new Date(
          dayjs()?.startOf("day").subtract(2, "days")?.toISOString()
        )?.getTime()
      );
      const endDate = Math.floor(
        new Date(
          dayjs()?.startOf("day").subtract(1, "days")?.toISOString()
        )?.getTime()
      );
      injectFilter({ startDate, endDate });
    } else if (identifier === "today") {
      const startDate = Math.floor(
        new Date(
          dayjs()?.startOf("day").subtract(1, "days")?.toISOString()
        )?.getTime()
      );
      const endDate = Math.floor(
        new Date(dayjs()?.startOf("day")?.toISOString())?.getTime()
      );
      injectFilter({ startDate, endDate });
    }

    const queryString = objectToQueryString(selectedFilter);
    setSearchParams(queryString);
  };

  const handleObjectInput = (obj: object) => {
    obj && injectFilter(obj);
    const queryString = objectToQueryString({ ...selectedFilter, ...obj });
    setSearchParams(queryString);
  };

  return isSelected ? (
    identifier === "custom_date" ? (
      <RangePicker handleOnSelect={handleObjectInput} />
    ) : (
      <Tag
        variant={isSelected ? "solid" : "outline"}
        colorScheme="primary"
        mr={2}
        mb={2}
      >
        {/* <TagLeftIcon boxSize="12px" as={AddIcon} /> */}
        <TagLabel fontWeight={"semibold"} fontSize={"sm"}>
          {label}
        </TagLabel>
        <TagCloseButton onClick={() => isSelected && handleOnclick("")} />
      </Tag>
    )
  ) : (
    <Tag
      variant={"outline"}
      colorScheme="primary"
      mr={2}
      mb={2}
      onClick={() => handleOnclick(identifier)}
      _hover={{ cursor: "pointer" }}
    >
      <TagLabel>{label}</TagLabel>
    </Tag>
  );
};

const TableLoader = () => {
  return (
    <Stack w={"full"} p={4} pos={"absolute"}>
      {Array(15)
        .fill(1)
        .map(() => (
          <Skeleton height="40px" w={"full"} />
        ))}
    </Stack>
  );
};

export function DataTable<Data extends object>({
  data,
  columns,
  isDataLoading,
}: DataTableProps<Data>) {
  const bg = useColorModeValue("white", "slate.800");
  const borderColor = useColorModeValue("slate.200", "slate.700");
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      {
        <Table
          variant="simple"
          size="sm"
          bg={bg}
          minH={data?.length > 10 ? "90vh" : "20vh"}
          overflow={"hidden"}
          pos={"relative"}
        >
          {data?.length > 0 && (
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const meta: any = header.column.columnDef.meta;
                    return (
                      <Th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        isNumeric={meta?.isNumeric}
                        fontSize="sm"
                        fontWeight="bold"
                        color="gray.600"
                        textTransform="uppercase"
                        cursor="pointer"
                        whiteSpace={"nowrap"}
                        border="0.10px solid "
                        borderColor={borderColor}
                        h={16}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
          )}
          {isDataLoading ? (
            <TableLoader />
          ) : data?.length <= 0 ? (
            <NoDataAvailable />
          ) : (
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <MemoizedRow key={row.id} row={row} />
              ))}
            </Tbody>
          )}
        </Table>
      }
    </>
  );
}

// For pagination, you can create a separate Pagination component
// This component will display the page numbers and handle the page change
type PaginationProps = {
  pageStartFrom?: number;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  maxVisiblePages?: number; // The maximum number of page numbers to show
};

export const Pagination = ({
  pageStartFrom,
  currentPage,
  totalPages,
  onPageChange,
  maxVisiblePages = 5, // Default to showing 5 page numbers at a time
}: PaginationProps) => {
  const history = useNavigate();
  const { getAllParams } = useUrlParams();
  const backgroundColorHover = useColorModeValue("slate.800", "slate.300");
  const showPreviousButton = currentPage !== (pageStartFrom || 0);
  const showNextButton = currentPage < totalPages;

  const getURLQuery = (count: number) => {
    const obj = getAllParams();
    obj["page"] = count;
    return objectToQueryString(obj);
  };

  const visiblePages = Array.from(
    { length: Math.min(maxVisiblePages, totalPages) },
    (_, i) => currentPage - Math.floor(maxVisiblePages / 2) + i
  ).filter((page) => page >= 1 && page <= totalPages);

  const handlePageChange = (page: number) => {
    if (page >= 0 && page <= totalPages) {
      onPageChange(page);
      history({
        pathname: window.location.pathname,
        search: getURLQuery(page),
      });
    }
  };

  return (
    <Box>
      <Flex justify="center" align="center">
        {showPreviousButton && (
          <Tooltip label={"Previous"} placement="top" hasArrow>
            <IconButton
              size={"sm"}
              borderRadius={"md"}
              disabled={currentPage === 0}
              aria-label="Icon button"
              variant={"outline"}
              icon={<Icon icon={"mingcute:left-line"} fontSize={22} />}
              onClick={() => handlePageChange(currentPage - 1)}
              _hover={{ bg: backgroundColorHover, color: "white" }}
              mr={2}
            />
          </Tooltip>
        )}

        {visiblePages.map((pageNumber) => (
          <Button
            _hover={{ bg: backgroundColorHover, color: "white" }}
            mx={1}
            size={"sm"}
            borderRadius={"md"}
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber)}
            variant={pageNumber === currentPage ? "solid" : "outline"}
            fontWeight={"semibold"}
            shadow={pageNumber === currentPage ? "md" : "none"}
          >
            {pageNumber}
          </Button>
        ))}

        {showNextButton && (
          <Tooltip label={"Next"} placement="top" hasArrow>
            <IconButton
              ml={2}
              borderRadius={"md"}
              size={"sm"}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              _hover={{ bg: backgroundColorHover, color: "white" }}
              aria-label="Icon button"
              variant={"outline"}
              icon={<Icon icon={"mingcute:right-line"} fontSize={22} />}
            />
          </Tooltip>
        )}
      </Flex>
    </Box>
  );
};

function NoDataAvailable() {
  const bg = useColorModeValue("white", "slate.800");
  return (
    <Box minH={"80vh"} minW={"80vw"} bg={bg}>
      <Center h={"75vh"}>
        <Text fontSize={"2xl"} fontWeight={"bold"}>
          No Data available{" "}
        </Text>
      </Center>
    </Box>
  );
}
