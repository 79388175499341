import { useState } from "react";
import {
  Flex,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  WrapItem,
  Wrap,
  Tag,
  TagLabel,
  Button,
  Heading,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
  Code,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import NetworkWrapper from "../../components/wrapper/NetworkWrapper";
import { useParams } from "react-router-dom";
import { useGetCase } from "../../hooks/api/useCaseApi";
import { getProperty } from "dot-prop";
import { CaseStatusRender, Header } from "../../components/table/CaseListingTableComponent";
import dayjs from "dayjs";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { usePaymentData } from "../../hooks/api/useWalletApi";
import { CodeRender } from "../dashboard/entity-tracing/EntityTracing";
import { DataTable } from "../../components/table/DataTable";
import { useEntityFilter } from "../../hooks/api/useEntityApi";

import GeoTag from "../../components/molecules/GeoTag";
import TextRenderWithIcon from "../../components/atom/TextRenderWithIcon";
import TextRenderWithHeading from "../../components/atom/TextRenderWithHeading";
import TransactionComponent from "../../components/molecules/TransactionComponent";
import { createColumnHelper } from "@tanstack/react-table";

import { withLazyLoad } from "../../components/atom/withLazyLoad";
import Nearby from "./Nearby";
import { TextMaker, convertPrice } from "../../utils";

const LiveEvents = withLazyLoad(() => import("../../components/molecules/LiveEvents"));

export default function Case() {
  return (
    <Flex>
      <Box w={"full"}>
        <Box w={"full"}>
          <Tabs variant="soft-rounded" colorScheme="green" size={"sm"} w={"full"}>
            <TabList>
              <Tab>Case Details</Tab>
              <Tab>Nearby</Tab>
              <Tab>Offers</Tab>
            </TabList>
            <TabPanels border={0}>
              <TabPanel p={[0, 4]}>
                <CaseDetails />
              </TabPanel>
              <TabPanel p={[0, 4]}>
                <Nearby />
              </TabPanel>
              <TabPanel p={[0, 4]}>
                <Offers />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
      <Box></Box>
    </Flex>
  );
}

const CaseDetails = () => {
  const { id: orderId } = useParams();
  const { data, ...rest }: any = useGetCase({ orderId });
  const { data: paymentData, ...paymentResponse } = usePaymentData({ orderId });
  const [isMobileView] = useMediaQuery("(max-width: 768px)");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isGeoTagOpen, onOpen: onGeoTagOpen, onClose: onGeoTagClose } = useDisclosure();

  const extractValue = (key: string) => (data?.data && getProperty(data.data.data, key)) || "";

  const extractValueFromPayment = (key: string) => (data?.data && getProperty(paymentData?.data, key)) || "";

  const darkSlate = useColorModeValue("gray.100", "slate.800");

  return (
    <NetworkWrapper status={rest}>
      <Flex>
        <Box flex="1" mr={[0, 6]}>
          <Wrap w={"full"} justify={"space-between"} bg={darkSlate} align={"center"} px={6} py={4} borderRadius={"md"}>
            <WrapItem>
              <Wrap spacing={5}>
                <WrapItem maxW={["full", 200]}>
                  <TextRenderWithHeading title="Order Id" description={extractValue("orderId")} />
                </WrapItem>
                <WrapItem maxW={["full", 200]}>
                  <Box>
                    <Text fontSize={"2xs"} fontWeight={"normal"} color="blackAlpha.600">
                      Case status
                    </Text>
                    <CaseStatusRender status={extractValue("orderStatus")} />
                  </Box>
                </WrapItem>
                <WrapItem maxW={["full", 200]}>
                  <Box>
                    <Text fontSize={"2xs"} fontWeight={"normal"} color="blackAlpha.600">
                      Created At
                    </Text>
                    <Tag size="sm" colorScheme="blue" variant={"outline"} opacity={0.8} borderRadius="full">
                      <TagLabel fontWeight={"semibold"}>{dayjs.unix((extractValue("createdAt") || 1000) / 1000).format("DD MMM YYYY hh:mm A")}</TagLabel>
                    </Tag>
                  </Box>
                </WrapItem>
                <WrapItem maxW={["full", 200]}>
                  <TextRenderWithHeading title="Source" description={extractValue("originSource.source")} />
                </WrapItem>
              </Wrap>
            </WrapItem>
            <WrapItem maxW={["full", 200]}>
              <Wrap>
                <WrapItem maxW={["full", 200]}>
                  <Button onClick={onOpen} borderRadius={"md"} variant={"solid"} size={"xs"} fontWeight={"semibold"} my={[5, 0]}>
                    Transactions
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button colorScheme="red" onClick={onGeoTagOpen} borderRadius={"md"} variant={"outline"} size={"xs"} fontWeight={"semibold"} my={[5, 0]} gap={2} display={["none", "flex"]}>
                    <Icon icon={"fluent:location-48-filled"} />
                    Geo Tag
                  </Button>
                </WrapItem>
              </Wrap>
            </WrapItem>
          </Wrap>

          <Wrap w={"full"} bg={darkSlate} px={6} py={4} mt={6} spacing={10} borderRadius={"md"}>
            <Box w={"full"} mb={"-6"}>
              <Heading fontWeight={"semibold"} fontSize={"small"}>
                Dispatch Details
              </Heading>
              <Divider my={2} />
            </Box>
            <WrapItem maxW={["full", 200]}>
              <TextRenderWithIcon icon="material-symbols:location-on-outline" title="Pickup Point" description={TextMaker(extractValue("waypoints[0]"))} />
            </WrapItem>
            <WrapItem maxW={["full", 200]}>
              <TextRenderWithIcon icon="mingcute:navigation-line" title="Drop" description={TextMaker(extractValue("waypoints[1]"))} />
            </WrapItem>
            <WrapItem maxW={200}>
              <TextRenderWithIcon icon={"ph:taxi-bold"} title={"Ambulance Type"} description={extractValue("entityRequired[0].subtype")} />
            </WrapItem>
            <WrapItem maxW={200}>
              <TextRenderWithIcon icon={"mdi:clock-time-three-outline"} title={"Dispatch"} description={extractValue("dispatchType") === "NOW" ? "Immediate" : "Scheduled"} />
            </WrapItem>
            <WrapItem maxW={200}>
              <TextRenderWithIcon
                icon={"game-icons:path-distance"}
                title={"Distance"}
                description={`${Number(extractValue("distance")) / 1000 > 1 ? (Number(extractValue("distance")) / 1000).toFixed(1) : (Number(extractValue("distance")) / 1000).toFixed(3)} Km`}
              />
            </WrapItem>
            <WrapItem maxW={200}>
              <TextRenderWithIcon
                icon={"tabler:external-link"}
                title={"Tracking Link"}
                render={() => (
                  <Link to={extractValue("assignmentDetails.trackingLink")} target="_blank">
                    <Flex alignItems={"center"}>
                      <Text fontSize={"xs"} fontWeight={"semibold"}>
                        Link
                      </Text>{" "}
                      <ExternalLinkIcon mx="2px" fontSize={"xs"} />
                    </Flex>
                  </Link>
                )}
              />
            </WrapItem>
            <WrapItem maxW={200}>
              <TextRenderWithIcon icon={"ep:platform"} title={"Platform"} description={extractValue("platform")} />
            </WrapItem>
            <WrapItem maxW={200}>
              <TextRenderWithIcon icon={"ep:user"} title={"Generated By"} description={extractValue("generatedBy")} />
            </WrapItem>
          </Wrap>

          <Wrap w={"full"} bg={darkSlate} px={6} py={4} mt={6} spacing={10} borderRadius={"md"}>
            <Box w={"full"} mb={"-6"}>
              <Heading fontWeight={"semibold"} fontSize={"small"}>
                Overridden Comments
              </Heading>
              <Divider my={2} />
            </Box>
            <WrapItem maxW={["full", 200]}>
              <TextRenderWithIcon icon="basil:comment-solid" title="Overridden Comments" description={extractValue("overridenComments")} />
            </WrapItem>
          </Wrap>

          <Stack w={"full"} bg={darkSlate} px={6} py={4} mt={6} spacing={10} borderRadius={"md"}>
            <Box w={"full"} mb={"-6"}>
              <Heading fontWeight={"semibold"} fontSize={"small"}>
                Contact Details
              </Heading>
              <Divider my={2} />
            </Box>
            <Wrap justify={"space-between"} maxW={["full", "400px"]}>
              <WrapItem maxW={["full", 200]}>
                <TextRenderWithIcon icon="mingcute:user-3-fill" title={"Caller Name"} description={extractValue("requestedBy.name")} />
              </WrapItem>
              <WrapItem maxW={["full", 200]}>
                <TextRenderWithIcon icon={"bi:phone"} title={"Caller Contact Number"} description={extractValue("requestedBy.mobile")} />
              </WrapItem>
            </Wrap>
            <Wrap justify={"space-between"} maxW={["full", "400px"]}>
              <WrapItem maxW={["full", 200]}>
                <TextRenderWithIcon icon={"mdi:patient-outline"} title={"Patient Name"} description={extractValue("requestedFor.name")} />
              </WrapItem>
              <WrapItem maxW={["full", 200]}>
                <TextRenderWithIcon icon="ic:round-local-phone" title={"Patient Contact Number"} description={extractValue("requestedFor.mobile")} />
              </WrapItem>
            </Wrap>
          </Stack>

          <Wrap w={"full"} bg={darkSlate} px={6} py={4} mt={6} spacing={10} borderRadius={"md"}>
            <Box w={"full"} mb={"-6"}>
              <Heading fontWeight={"semibold"} fontSize={"small"}>
                Patient info
              </Heading>
              <Divider my={2} />
            </Box>

            <WrapItem maxW={["full", 200]}>
              <TextRenderWithIcon icon="uil:heart-medical" title={"Medical Condition"} description={(extractValue("requestedFor.medicalIssue.reason") || []).join(",")} />
            </WrapItem>
            <WrapItem maxW={["full", 200]}>
              <TextRenderWithIcon icon={"mdi:weight-lifter"} title={"Weight"} description={`${extractValue("requestedFor.weight") || "0"} Kg`} />
            </WrapItem>

            <WrapItem maxW={["full", 200]}>
              <TextRenderWithIcon
                icon={"game-icons:body-height"}
                title={"Height"}
                description={`${extractValue("requestedFor.height.feet") || 0} ft ${extractValue("requestedFor.height.inches") || 0} inch`}
              />
            </WrapItem>
            <WrapItem maxW={["full", 200]}>
              <TextRenderWithIcon icon="bi:gender-trans" title={"Gender"} description={extractValue("requestedFor.gender") || "Unknown"} />
            </WrapItem>
            <WrapItem maxW={["full", 200]}>
              <TextRenderWithIcon icon="noto:mage" title={"Age"} description={`${extractValue("requestedFor.age") || "Unknown"} Years`} />
            </WrapItem>
          </Wrap>

          <Wrap w={"full"} bg={darkSlate} px={6} py={4} mt={6} spacing={10} borderRadius={"md"}>
            <Box w={"full"} mb={"-6"}>
              <Heading fontWeight={"semibold"} fontSize={"small"}>
                Squad Details
              </Heading>
              <Divider my={2} />
            </Box>

            <WrapItem maxW={["full", 200]}>
              <TextRenderWithIcon
                icon="ph:lifebuoy"
                title={"Pilot Name"}
                render={() => (
                  <Box>
                    <Text fontSize={"xs"} fontWeight={"semibold"}>
                      {extractValue("assignmentDetails.pilotDetails.meta.userName") || "-"}
                    </Text>
                    <Text fontSize={"xs"} fontWeight={"semibold"}>
                      {extractValue("assignmentDetails.pilotDetails.meta.mobile") || "-"}
                    </Text>
                  </Box>
                )}
              />
            </WrapItem>
            <WrapItem maxW={["full", 200]}>
              <TextRenderWithIcon icon={"ph:taxi-bold"} title={"Vehicle Number"} description={`${extractValue("assignmentDetails.fleetDetails.ambNumberPlate") || "Unknown"}`} />{" "}
            </WrapItem>

            <WrapItem maxW={["full", 200]}>
              <TextRenderWithIcon icon={"ph:office-chair-bold"} title={"Ownership"} description={extractValue("vehicleNumber") || "-"} />{" "}
            </WrapItem>
            <WrapItem maxW={["full", 200]}>
              <TextRenderWithIcon icon="mdi:identification-card-outline" title={"Vehicle"} description={extractValue("entityRequired[0].subtype") || "Unknown"} />{" "}
            </WrapItem>
            <WrapItem maxW={["full", 200]}>
              <TextRenderWithIcon
                icon="ph:first-aid-kit"
                title={"Paramedic Name"}
                render={() => (
                  <Box>
                    <Text fontSize={"xs"} fontWeight={"semibold"}>
                      {extractValue("assignmentDetails.paramedicDetails.meta.userName") || "-"}
                    </Text>
                    <Text fontSize={"xs"} fontWeight={"semibold"}>
                      {extractValue("assignmentDetails.paramedicDetails.meta.mobile") || "-"}
                    </Text>
                  </Box>
                )}
              />{" "}
            </WrapItem>
            <WrapItem maxW={["full", 200]}>
              <TextRenderWithIcon
                icon="ph:arrows-merge-bold"
                title={"Dedicated/ Independent"}
                description={extractValue("assignmentDetails.fleetDetails.isDedicated") ? (extractValue("assignmentDetails.fleetDetails.isDedicated") ? "Dedicated" : "Independent") : "Unknown"}
              />{" "}
            </WrapItem>
          </Wrap>

          <Stack w={"full"} bg={darkSlate} px={6} py={4} mt={6} spacing={10} borderRadius={"md"}>
            <Box w={"full"} mb={"-6"}>
              <Heading fontWeight={"semibold"} fontSize={"small"}>
                Payment & Add-Ons
              </Heading>
              <Divider my={2} />
            </Box>
            <Wrap>
              <WrapItem maxW={["full", 200]}>
                <TextRenderWithIcon
                  icon={"mingcute:bill-fill"}
                  title={"Bill To"}
                  render={() => {
                    return extractValue("billToClient") ? (
                      <Flex align={"center"}>
                        <Icon icon={"flat-color-icons:ok"} />{" "}
                        <Text ml={2} fontSize={"xs"} fontWeight={"semibold"}>
                          Bill to Hospital
                        </Text>
                      </Flex>
                    ) : (
                      <Flex align={"center"}>
                        <Icon icon={"flat-color-icons:ok"} />{" "}
                        <Text ml={2} fontSize={"xs"} fontWeight={"semibold"}>
                          Bill to Patient
                        </Text>
                      </Flex>
                    );
                  }}
                />
              </WrapItem>
            </Wrap>
            <Wrap justify={"space-between"} maxW={["full", "600px"]}>
              <WrapItem maxW={["full", 200]}>
                <TextRenderWithIcon icon="tabler:sum" title={"Total Amount Payable"} description={`₹ ${convertPrice(parseInt(extractValue("totalFare") || "0")).toString()}`} />
              </WrapItem>
              <WrapItem maxW={["full", 200]}>
                <TextRenderWithIcon
                  icon="ic:round-paid"
                  title={"Amount Paid"}
                  description={`₹ ${convertPrice((extractValueFromPayment("totalAmount") && (extractValueFromPayment("totalAmount") - extractValueFromPayment("amountPending")) / 100) || 0)}`}
                />
              </WrapItem>
              <WrapItem maxW={["full", 200]}>
                <TextRenderWithIcon icon="solar:bill-bold-duotone" title={"Amount to be paid"} description={`₹ ${convertPrice(extractValueFromPayment("amountPending") || "0")}`} />
              </WrapItem>
            </Wrap>
            <Wrap justify={"space-between"} maxW={["full", "600px"]}>
              <WrapItem maxW={["full", 200]}>
                <TextRenderWithIcon icon="bxs:ambulance" title={"Ambulance Fare"} description={`₹ ${convertPrice(parseInt(extractValue("fareAgreed") || "0")).toString()}`} />
              </WrapItem>
              <WrapItem maxW={["full", 200]}>
                <TextRenderWithIcon icon="icon-park-solid:add-one" title={"Add-Ons Fare"} description={`₹ ${convertPrice(parseInt(extractValue("addonsFare") || "0")).toString()}`} />
              </WrapItem>
              <WrapItem maxW={["full", 200]}>
                <TextRenderWithIcon icon="mdi:counter" title={"Add-Ons (Qty)"} description={getAddonsName(extractValue("addons") || [])} />
              </WrapItem>
            </Wrap>
          </Stack>

          <Wrap w={"full"} bg={darkSlate} px={6} py={4} mt={6} spacing={10} borderRadius={"md"}>
            <Box w={"full"} mb={"-6"}>
              <Heading fontWeight={"semibold"} fontSize={"small"}>
                Margins & Discounts
              </Heading>
              <Divider my={2} />
            </Box>
            <WrapItem maxW={["full", 300]}>
              <TextRenderWithIcon icon="mdi:margin" title="Margin" description={extractValue("stanplusMargin")} />
            </WrapItem>
            {/* // TODO: need to add discount */}
            <WrapItem maxW={["full", 300]}>
              <TextRenderWithIcon icon="iconamoon:discount-fill" title="Discount" description={extractValue("stanplusMargin")} />
            </WrapItem>
          </Wrap>
        </Box>

        {isMobileView ? <MobileContainer extractValue={extractValue} /> : <DesktopContainer extractValue={extractValue} />}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx={3}>
          <ModalHeader fontSize={"sm"}>Case Transaction Log</ModalHeader>
          <Divider mt={-2} />
          <ModalCloseButton />
          <ModalBody>
            <TransactionComponent />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isGeoTagOpen} onClose={onGeoTagClose} size={"5xl"}>
        <ModalOverlay />
        <ModalContent mx={3}>
          <ModalHeader fontSize={"sm"}>Case Transaction Log</ModalHeader>
          <Divider mt={-2} />
          <ModalCloseButton />
          <ModalBody>
            <GeoTag extractValueFromCase={extractValue} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </NetworkWrapper>
  );
};

const MobileContainer = ({ extractValue }: any) => {
  const [isVisible, setVisible] = useState(false);
  const [activeTabName, setActiveTabName] = useState("Case View");

  const darkSlate = useColorModeValue("gray.100", "slate.800");

  const onTabSelect = (key: string) => {
    if (key === "Case View") {
      setVisible(false);
      setActiveTabName("");
    } else {
      setVisible(true);
      setActiveTabName(key);
    }
  };
  return (
    <Box>
      {isVisible ? (
        <Box w={"full"}>
          <Box w={"full"} bg={darkSlate} h={"full"} pos={"fixed"} left={0} top={0} zIndex={200} borderRadius={"md"} p={5} overflowY={"scroll"} py={10} pb={20}>
            <RenderMapper extractValue={extractValue} activeTab={activeTabName} />
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <Box pos={"fixed"} left={0} bottom={5} w={"full"} zIndex={250}>
        <Tabs variant="soft-rounded" colorScheme="orange" size={"sm"} w={"full"} px={5}>
          <TabList bg={"white"} borderWidth={1} shadow={"md"} py={2} w={"full"} borderRadius={"full"} justifyContent={"space-between"}>
            <Tab onClick={() => onTabSelect("Case View")}>
              {/* <Icon icon={"mdi:report-box"} fontSize={20} /> */}
              Case View
            </Tab>
            <Tab onClick={() => onTabSelect("Live Events")} fontSize={"xs"}>
              Live Events
            </Tab>
            {/* <Tab onClick={() => onTabSelect("Comments")} fontSize={"xs"}>
              Comments
            </Tab> */}
            <Tab onClick={() => onTabSelect("Geo Tags")} fontSize={"xs"}>
              Geo Tag
            </Tab>
          </TabList>
        </Tabs>
      </Box>
    </Box>
  );
};

const DesktopContainer = ({ extractValue }: any) => {
  const darkSlate = useColorModeValue("white", "slate.800");
  return (
    <Box w={"400px"}>
      <Box w={"400px"} bg={darkSlate} h={"85vh"} pos={"fixed"} borderRadius={"md"} p={5} overflowY={"scroll"}>
        <RenderMapper extractValue={extractValue} />
      </Box>
    </Box>
  );
};

const RenderMapper = ({ activeTab = "Live Events", extractValue }: { activeTab?: string; extractValue: any }) => {
  if (activeTab === "Live Events") {
    return <LiveEvents />;
  }
  // else if (activeTab === "Comments") {
  //   return <Comments />;
  // }
  else if (activeTab === "Geo Tags") {
    return <GeoTag extractValueFromCase={extractValue} />;
  } else {
    return <>No View Found</>;
  }
};

// const Comments = () => {
//   return <Box>Chat box</Box>;
// };

function getAddonsName(addons: any) {
  if (addons?.length > 0) {
    return addons?.reduce((str: string, addon: any) => str.concat(`${addon.type} x ${addon.quantity}`), "");
  } else {
    return "-";
  }
}

export const Offers = () => {
  const { id: orderId } = useParams();

  const payload = {
    page: 1,
    traces: orderId,
    serviceNameText: "ASSIGNMENT_SERVICE",
    sortOrder: "desc",
  };

  const {
    data: entityFilter,
    rest,
    meta,
    setPage,
  } = useEntityFilter({
    customTraces: orderId,
    userPayload: payload,
  });

  const columnHelper = createColumnHelper<any>();

  const columns = [
    columnHelper.accessor("eventName", {
      cell: info => (
        <Text whiteSpace={"nowrap"} ml={3} fontWeight={"semibold"}>
          {info.getValue()}
        </Text>
      ),
      header: () => <Header text={"*️⃣ Event Name"} />,
    }),

    columnHelper.accessor("timestamp", {
      cell: info => (
        <Box px={3} borderRadius={"full"}>
          <Text whiteSpace={"nowrap"}>{dayjs.unix(info.getValue() / 1000).format("DD MMM YYYY hh:mm A")}</Text>
        </Box>
      ),
      header: () => <Text align={"left"}>🕐 Time Stamp </Text>,
    }),
    columnHelper.accessor("data", {
      cell: info => (
        <Code p={2} borderRadius={"md"} maxW={300}>
          <Text textTransform={"capitalize"} fontSize={"xs"}>
            {JSON.parse(info.getValue())?.message}
          </Text>
        </Code>
      ),
      header: () => <Text align={"left"}>📧 Message </Text>,
    }),
    columnHelper.accessor("data", {
      cell: info => <CodeRender code={info.getValue()} />,
      header: () => <Text align={"left"}>💻 Data</Text>,
    }),
  ];

  return (
    <NetworkWrapper status={rest}>
      <Box borderRadius={"md"} maxH={["90vh", "85vh"]} maxW={["95vw", "80vw"]} minW={["95vw", "80vw"]} overflow={"scroll"} bg={"white"} shadow={"2xl"} className="custom-scrollbar">
        <DataTable isDataLoading={rest.isLoading || rest.isLoading} columns={columns} data={entityFilter} />
      </Box>
    </NetworkWrapper>
  );
};
