import { useState } from "react";
import { objectToQueryString, objectToQueryStringOmitEmpty } from "../../utils";
import { useRedStore } from "@store/store";

export default function useCaseFilter() {
  const loginUser = useRedStore(state => state.user);
  const [filters, setFilters] = useState({
    page: 0,
    limit: 50,
    sortType: "desc",
  });

  const getFilterQueryString = (obj?: any) => {
    return objectToQueryString({ ...filters, ...obj });
  };

  const getMeta = () => {
    return {
      page: filters.page,
      limit: filters.limit,
    };
  };

  const userAnalytics = () => {
    const obj = { startDate: 1707676200000, endDate: 1707762599999, generatedBy: loginUser?.email };
    return objectToQueryStringOmitEmpty(obj);
  };

  return { getFilterQueryString, getMeta, userAnalytics };
}
