import { getProperty, setProperty } from "dot-prop";
import { create as CreateRedStore } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { IAddons, ICaseResponseCurrentCaseObject, ICaseResponseObject } from "../config/types";
import { debounce } from "lodash";

export type TPropertyType = keyof ICaseResponseCurrentCaseObject | "_selectedSource" | "sourceName";

interface ICreateCase {
  case: ICaseResponseObject;
  changes: any;
  setCase: (value: ICaseResponseObject) => void;
  setCurrentCase: (value: any) => void;
  setCurrentCaseMulti: (value: any) => void;
  setAddons: (value: IAddons[]) => void;
  setCaseProps: (propertyName: TPropertyType, value: any) => void;
  resetCurrentChanges: () => void;
  resetCaseProps: () => void;
  setSelectedDateTime: (value: number | null) => void;
}

export const useCreateCaseStore = CreateRedStore<ICreateCase>()(
  subscribeWithSelector(set => ({
    case: {},
    changes: {},
    setCase: value => {
      return set(() => ({ case: value }));
    },
    setCurrentCase: value => {
      return set(() => ({ changes: value }));
    },
    setCurrentCaseMulti: (value: any) => {
      return set(state => ({ changes: { ...state.changes, ...value } }));
    },
    setSelectedDateTime: value =>
      set(state => {
        const newCase = setProperty(state.changes, "scheduledDdtm", value);
        const newState = { changes: { ...state.changes, ...newCase } };
        return newState;
      }),
    setAddons: (addons: any) =>
      set(state => {
        const newCase = setProperty(state.changes, "addons", addons);
        const newState = { changes: { ...state.changes, ...newCase } };
        return newState;
      }),

    setCaseProps: debounce(
      (property, value) =>
        set(state => {
          const newCase = setProperty(state.changes, property, value);
          const newState = { changes: { ...state.changes, ...newCase } };
          return newState;
        }),

      300,
      { leading: true, trailing: true }
    ),
    resetCurrentChanges: () =>
      set(() => {
        const newState = { changes: {} };
        console.log(newState);
        return newState;
      }),
    resetCaseProps: () =>
      set((state: any) => {
        const newState = { case: {} };
        // eslint-disable-next-line no-console
        console.log(newState);
        return newState;
      }),
  }))
);

// insertItem: (property, value) =>
//   set((state) => {
//     const newValue: any = getProperty(state.case, property) || []

//     if (newValue?.includes(value)) {
//       const newCase = setProperty(
//         state.case,
//         property,
//         newValue.filter((itm: string) => itm !== value),
//       )
//       return { case: newCase }
//     } else {
//       const newCase = setProperty(state.case, property, [...newValue, value])
//       return { case: newCase }
//     }
//   }),
// setCaseArrayProps: (property, value) =>
//   set((state) => {
//     const newValue: any = getProperty(state.case, property)
//     newValue.push(value)
//     const newCase = setProperty(state.case, property, newValue)
//     const newState = { case: newCase }
//     // eslint-disable-next-line no-console
//     console.log(newState)
//     return newState
//   }),
