export default {
  featureName: "Lama/Dama Feature",
  isActive: true,
  userSegments: [],
  payloadInject: function (payload: any) {
    if (!this.isActive) {
      delete payload["isLamaDamaSelected"];
    }
    return payload;
  },
};
