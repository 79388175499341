import { useState } from "react";
import covidFeatures from "@features/covid-switch";
import lamadamaSwitchFeatures from "@features/lamadama-switch";
import { useRedStore } from "@store/store";

const initState = { "Covid Feature": false, "Lama/Dama Feature": false };

// API payload uniqId
const apiNames = {
  "dispatch-api": [covidFeatures],
};

const features = [covidFeatures, lamadamaSwitchFeatures];

const featureInitializationPromise = () =>
  new Promise((resolve, reject) => {
    const featureObj = features?.reduce((a: any, b) => {
      a[b.featureName] = b.isActive;
      return a;
    }, initState);
    resolve(featureObj);
  });

export default function useFeature() {
  const [activeFeatures, setActiveFeatures]: any = useState(initState);
  const hasAccess = (featureName: keyof typeof initState) => {
    const feature = activeFeatures[featureName];
    return feature;
  };

  const initFeature = async () => {
    const initialFeatureStatus = await featureInitializationPromise();
    // console.log("Feature calll::: init call");
    setActiveFeatures(initialFeatureStatus);
  };

  const injectPayload = (featureName: keyof typeof apiNames, payload: object) => {
    const featureConfig = apiNames[featureName];
    if (featureConfig) {
      featureConfig?.reduce((a, b) => {
        if (b.payloadInject) {
          return b.payloadInject(a);
        }
        return a;
      }, payload);
    }
  };

  return { initFeature, hasAccess, injectPayload };
}
