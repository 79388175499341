import { useState } from 'react'

const PAGINATION_LIMIT = 50

export interface IPaginationMeta {
  totalRecords: number
  pageIndex: number
  limit: number
  pageCount: number
}

export default function usePaginationData(initialPageCount?: number, initialPageLimit?: number) {
  const [meta, setMeta] = useState<IPaginationMeta>({
    totalRecords: 0,
    pageIndex: initialPageCount || 0,
    limit: initialPageLimit ?? PAGINATION_LIMIT,
    // TODO: some api pagination started from 1 or 0 so this need to be configurable
    pageCount: 0,
  })

  const setupPaginationState = (totalRecords: number, defaultPageIndex?: number) => {
    const pageCount = parseInt((totalRecords / meta.limit).toString())
    setMeta((prev) => ({
      ...prev,
      totalRecords,
      pageIndex: defaultPageIndex || 0,
      pageCount,
    }))
  }

  const goNext = () => {
    const { limit, pageIndex, totalRecords } = meta
    const itemLoaded = limit * (pageIndex + 1)
    if (itemLoaded < totalRecords) {
      setMeta((prev) => ({
        ...prev,
        pageIndex: prev.pageIndex + 1,
      }))
    }
  }

  const setPage = (pageIndex: number) => {
    setMeta((prev) => ({
      ...prev,
      pageIndex,
    }))
  }

  const goPrevious = () => {
    if (meta.pageIndex > 0) {
      setMeta((prev) => ({
        ...prev,
        pageIndex: prev.pageIndex - 1,
      }))
    }
  }

  const resetPagination = () => {
    setMeta({
      totalRecords: 0,
      pageIndex: 0,
      limit: 20,
      pageCount: 0,
    })
  }

  return { meta, goNext, setPage, goPrevious, resetPagination, setupPaginationState }
}
