import { CLOUD_FUNCTION_BASE_URL, ENVIRONMENT } from "../axios-interceptor";
import request from "../request";

const urls = {
  filterNames: `/entity-tracing-${ENVIRONMENT}-filter`,
  eventNames: `/entity-tracing-${ENVIRONMENT}-getEventNames`,
  serviceNames: `/entity-tracing-${ENVIRONMENT}-getServiceNames`,
  squadByEntityIds: "/squad/squadByEntityIds",
};

export const getEntityFilter = (payload?: any) => {
  const response = request("POST", `${urls.filterNames}`, payload, {
    baseURL: CLOUD_FUNCTION_BASE_URL,
  });
  return response;
};

export const getEventNames = () => {
  const response = request(
    "GET",
    `${urls.eventNames}`,
    {},
    { baseURL: CLOUD_FUNCTION_BASE_URL }
  );
  return response;
};

export const getServiceNames = () => {
  const response = request(
    "GET",
    `${urls.serviceNames}`,
    {},
    { baseURL: CLOUD_FUNCTION_BASE_URL }
  );
  return response;
};

export const getSquadByEntityIds = (payload?: any) => {
  const response = request("POST", `${urls.squadByEntityIds}`, payload);
  return response;
};
