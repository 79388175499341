import {
  getEventNames,
  getEntityFilter,
  getServiceNames,
  getSquadByEntityIds,
} from "../../api/service/entity";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { ICaseResponseObject } from "../../config/types";
import usePaginationData, { IPaginationMeta } from "../action/usePagination";
import { useLocation, useParams } from "react-router-dom";
import { objectToQueryString } from "../../utils";

type ResponseType = any;

interface IResponse {
  data: ICaseResponseObject[];
  meta: IPaginationMeta;
  rest: any;
  goNext: () => void;
  goPrevious: () => void;
  setPage: (index: number) => void;
}

interface IUseEntityFiler {
  userPayload?: any;
  onSuccess?: (data: AxiosResponse<any>) => void;
  customTraces?: string;
}

const useEntityFilter = (props: IUseEntityFiler): IResponse => {
  const { onSuccess, customTraces, userPayload } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageParam = queryParams.get("page");
  const traces = customTraces || queryParams.get("traces");
  const serviceNameText = queryParams.get("serviceNameText");
  const eventName = queryParams.get("eventName");
  const sortBy = queryParams.get("sortBy");

  // const loginUser = useAppStore((state) => state.loginUser)
  const { meta, goNext, setPage, goPrevious, setupPaginationState } =
    usePaginationData(1);
  const [data, setData] = useState([]);
  const pageNo = pageParam ? parseInt(pageParam) : meta.pageIndex;

  let payload: any = {
    limit: meta.limit,
    page: pageNo,
    sortType: sortBy || "desc",
    traces,
    eventName,
    serviceNameText,
  };

  if (userPayload) {
    payload = { ...payload, ...userPayload };
  }

  const onEntitySuccess = (response: AxiosResponse<ResponseType>) => {
    const { data } = response;
    if (data?.data && data.data?.length > 0) {
      // TODO: need to modify it to class based instance
      setData(data.data);
      setupPaginationState(data.count, pageNo || 1);
      onSuccess && onSuccess(data);
    } else {
      setData([]);
      setupPaginationState(0, 0);
    }
  };

  const { mutateAsync, ...rest } = useMutation(getEntityFilter, {
    // enabled: !!payload.city,
    onSuccess: onEntitySuccess,
  });

  // const hitApi = () => {
  //   mutateAsync(payload)
  // }

  useEffect(() => {
    mutateAsync(payload);
  }, [objectToQueryString(payload)]);

  return { data, meta, rest, goPrevious, setPage, goNext };
};

const useEntityServiceApi = () => {
  return useQuery(["get-entity-service-names"], getServiceNames);
};

const useEntityEventApi = () => {
  return useQuery(["get-entity-event-names"], getEventNames);
};

const useSquadMapDetails = () => {
  const { id: orderId } = useParams();

  const payload = {
    eventName: "ASSIGNMENT",
    page: 1,
    serviceNameText: "DISPATCH_SERVICE",
    sortOrder: "asc",
    traces: orderId,
  };

  const { data, rest }: any = useEntityFilter({
    customTraces: orderId,
    userPayload: payload,
  });

  // const { mutateAsync, ...rest } = useMutation(getSquadByEntityIds, {
  //   onSuccess: onEntitySuccess,
  // });

  // useEffect(() => {
  //   if (!!data && data?.length > 0) {
  //     const newData:any = []
  //     const newReqPayload:any = []

  //     data.forEach((itm:any) => {
  //       const modData = data?.data ? JSON.parse(data.data)?.data : {}

  //       const temp:any = {
  //          entityId: getProperty(itm, 'entityId'),
  //         timeline: getProperty(itm, 'timeline'),
  //         timestamp: getProperty(itm, 'timestamp'),
  //         pool: getProperty(modData, 'pool'),
  //         customerInfo: getProperty(modData, 'customerInfo'),
  //         trips: getProperty(modData, 'trips'),
  //       }

  //       newData.push(temp)

  //       if(temp?.pool?.length > 0) {
  //         const arr:any = []
  //         temp?.pool.forEach((itm:any) => {
  //           arr.push(itm?.entityId)
  //         })
  //         newReqPayload.push(arr)
  //       }
  //     })

  //     const modifyData = (data: any) => {
  //       const _processData = data
  //         ? data.map((itm: any) => ({
  //             ...itm,
  //             location: { lat: itm.lat, lng: itm.lng },
  //           }))
  //         : [];

  //       console.log("process: ", _processData, " modData:", modData);
  //       return _processData;
  //     };

  //         const payload: any = []

  //       mutateAsync(payload);
  //       }
  //     }, [data]);

  // ----------------------------- get all types --------------------------
  // const payload2 = {
  //   eventName: "",
  //   page: 1,
  //   serviceNameText: "ASSIGNMENT_SERVICE",
  //   sortOrder: "desc",
  // };

  // const { data, rest, meta, setPage }: any = useEntityFilter({
  //   customTraces: orderId,
  //   userPayload: payload,
  // });

  return { data, rest };
};

const useSquadById = ({ onSuccess }: any) => {
  return useMutation(getSquadByEntityIds, { onSuccess });
};

export {
  useEntityEventApi,
  useEntityServiceApi,
  useEntityFilter,
  useSquadMapDetails,
  useSquadById,
};
